import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import Icon from '../Icon';
import { paginationContainer, shownResultsIndicator, paginationButton } from './styles';

const Pagination = forwardRef(({ tableOptions, getData }, ref) => {
  const { getText } = useTranslations();
  const [page, setPage] = useState(0);
  const { totalPages, pageIndex: pageProp, pageSize, totalResults } = tableOptions.current;
  const noResults = totalPages === 0;

  useImperativeHandle(ref, () => ({ page, setPage }));

  useEffect(() => {
    setPage(pageProp);
  }, [pageProp]);

  const handlePageChange = (newPage) => {
    getData({ pageIndex: newPage });
    setPage(newPage);
  };

  const baseButton = (disabled, type) => (
    <span
      css={paginationButton(disabled)}
      {...(!disabled && {
        onClick: () => handlePageChange(page - (type === 'left' ? 1 : -1)),
      })}>
      {type === 'left' ? '' : getText('next')}
      <Icon material size={28} iconName={type === 'left' ? 'chevron_left' : 'chevron_right'} />
      {type === 'left' ? getText('previous') : ''}
    </span>
  );

  const fromIndex = page * pageSize + 1;
  const toIndex = (page + 1) * pageSize;

  return (
    <div css={paginationContainer}>
      <div css={shownResultsIndicator}>{`${fromIndex} - ${toIndex > totalResults ? totalResults : toIndex}`}</div>
      {baseButton(page === 0 || noResults, 'left')}
      {baseButton(page === totalPages - 1 || noResults, 'right')}
    </div>
  );
});

Pagination.propTypes = {
  tableOptions: PropTypes.object,
  getData: PropTypes.func,
};

export default Pagination;
