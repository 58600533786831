import { privacyStyles } from './styles';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1 css={privacyStyles}>Privacy page</h1>
    </div>
  );
};

export default PrivacyPolicy;
