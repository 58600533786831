import { useEffect, useState } from 'react';
import { isNull, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, showApiError } from '../../../UI';
import { useAuth } from '../../../UI/Authenticate';
import { getAllCourses } from '../../../../services/courses';
import { mightLikeWrapper } from './styles';
import MightLikeCard from '../../../Shared/MightLikeCard';

const MightLikeCourses = (props) => {
  const { categoryId = null } = props;
  const { getText } = useTranslations();
  const {
    user: {
      academyAccount: { id: accountId },
      myPackages,
      currentAccount,
    },
  } = useAuth();
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, [categoryId, currentAccount?.id]);

  const fetchCourses = async () => {
    const [res, err] = await getAllCourses({
      notAccountId: accountId,
      categoryId,
      includeDetails: true,
      randomize: true,
    });
    if (err) return showApiError(err);

    const filteredCourses = res?.data
      ?.slice(0, 4)
      ?.filter(
        (item) =>
          !myPackages.some((elem) =>
            item.catalogueItems.map((el) => el?.catalogueItemId).includes(elem?.catalogueItemId),
          ),
      );

    setCourses(filteredCourses);
  };

  return (
    <div css={mightLikeWrapper}>
      <Row container>
        <Col xs={12}>
          <h2 className="section-title">{getText('coursesMightLike')}</h2>
        </Col>
      </Row>
      {!isNull(courses) && (
        <Row horizontalGap={12} noWrap container>
          {!isEmpty(courses) &&
            courses?.map((el) => (
              <Col xs={12} sm={6} md={4} lg={3} key={el.id} className="bottomBuffer">
                <MightLikeCard course={el} />
              </Col>
            ))}
        </Row>
      )}
    </div>
  );
};

export default MightLikeCourses;
