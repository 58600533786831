import { useEffect, useState, useRef } from 'react';
import { softLogout } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { useAuth } from '../Authenticate';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import AccountsList from './AccountsList';
import { ReactComponent as ChevronDownIcn } from '../../../assets/images/chevron-down-navbar.svg';
import {
  profileIconStyles,
  profileDropdownBtn,
  viewProfileStyle,
  viewProfileDropdown,
  signOutStyle,
  profilePicName,
  titleAndLabelDropdown,
} from './styles';

const UserDropdown = () => {
  const { getText } = useTranslations();
  const [showList, seShowList] = useState(false);
  const {
    user: { profile, currentAccount },
  } = useAuth();

  const switchRef = useRef();

  useEffect(() => {
    !showList && switchRef.current.focus();
  }, [showList]);

  const handleBack = () => {
    seShowList(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    softLogout();
  };

  return (
    <Tooltip
      position="bottom"
      align="right"
      onClick
      content={
        <div css={viewProfileDropdown}>
          {showList ? (
            <AccountsList onBack={handleBack} />
          ) : (
            <>
              <div css={viewProfileStyle}>
                <div className="wrapper">
                  <div css={profilePicName}>
                    {profile?.picThumbnailUrl ? (
                      <img src={profile.picThumbnailUrl} alt="User Profile" css={profileIconStyles} />
                    ) : (
                      <Icon iconName="la la-user" css={profileIconStyles} />
                    )}
                    <div css={titleAndLabelDropdown}>
                      <h3 className="title">{profile?.firstName}</h3>
                      <span className="label">#{currentAccount?.id}</span>
                    </div>
                  </div>
                </div>
                <div ref={switchRef} className="swap-icon" onClick={() => seShowList(true)} role="button" tabIndex={0}>
                  <Icon iconName="la la-exchange" />
                </div>
              </div>
              <div role="button" tabIndex={0} onClick={handleLogout} css={signOutStyle}>
                <Icon iconName="la la-sign-out" />
                <h4 className="title">{getText('signOut')}</h4>
              </div>
            </>
          )}
        </div>
      }>
      <div css={profileDropdownBtn}>
        {profile?.picThumbnailUrl ? (
          <img src={profile.picThumbnailUrl} alt="User Profile" css={profileIconStyles} />
        ) : (
          <Icon iconName="la la-user" css={profileIconStyles} />
        )}
        <ChevronDownIcn />
      </div>
    </Tooltip>
  );
};

export default UserDropdown;
