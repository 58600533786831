import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslations } from '@veraio/strank';
import { Button, showApiError } from '../../../UI';
import { useAuth } from '../../../UI/Authenticate';
import { getAllCourses } from '../../../../services/courses';
import { getAccountAchievements } from '../../../../services/accounts';
import CarouselCard from './CarouselCard/CarouselCard';
import logoDashboard from '../../../../assets/images/dashboardLogo.svg';
import {
  avatarWrap,
  dashboardHeaderText,
  dashboardHeaderTextAndBtnWrapper,
  dashboardBtn,
  customArrowStyle,
  customDotStyle,
  wrapper,
} from './styles';

const responsive = {
  mobile: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
  },
};

const DashboardHeader = () => {
  const {
    user: { profile },
    academyAccount: { id: accountId } = {},
  } = useAuth();
  const { getText } = useTranslations();
  const [courses, setCourses] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log('achievements', achievements);

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    fetchAchievements();
  }, [accountId]);

  const fetchCourses = async () => {
    setIsLoading(true);
    const [res, err] = await getAllCourses({ includeDetails: true });
    if (err) {
      setIsLoading(false);
      return;
    }

    setCourses(res?.data.slice(0, 3) ?? []);
    setIsLoading(false);
  };

  const fetchAchievements = async () => {
    const [res, err] = await getAccountAchievements(accountId);
    if (err) return showApiError(err);

    setAchievements(res?.badges ?? []);
  };

  const CustomArrow = ({ onClick, direction }) => (
    <div css={customArrowStyle(direction)}>
      <button onClick={onClick} className="carousel-arrow">
        {direction === 'left' ? <i className="fas fa-chevron-left" /> : <i className="fas fa-chevron-right" />}
      </button>
    </div>
  );

  const CustomDot = ({ onClick, active }) => {
    return <div role="button" tabIndex={0} onClick={onClick} css={(theme) => customDotStyle(theme, active)} />;
  };

  return (
    <>
      <div css={avatarWrap} className="test">
        <img src={logoDashboard} alt="dashboard-logo" />
        <div>
          <h2 className="userName">{`${getText('welcomeBack')}, ${profile?.firstName}`}</h2>
          <div css={dashboardHeaderTextAndBtnWrapper}>
            <p css={dashboardHeaderText}>{'You have earned 24 points'}</p>
            <Button outline css={dashboardBtn} small>
              {getText('viewDetails')}
            </Button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Carousel
          showDots
          infinite
          autoPlay
          customDot={<CustomDot />}
          customLeftArrow={<CustomArrow direction="left" />}
          customRightArrow={<CustomArrow direction="right" />}
          responsive={responsive}
          autoPlaySpeed={3000}
          css={wrapper}>
          {courses.map((course) => (
            <CarouselCard
              key={course.id}
              title={course.name}
              category={course?.category?.name}
              description={course?.description}
              lessons={course?.lessonsCount}
              totalVideoDurationInSeconds={course?.totalVideoDurationInSeconds}
              image={course?.pictureUrl}
              isFeatured={course?.isFeatured}
              id={course?.id}
            />
          ))}
        </Carousel>
      )}
    </>
  );
};

export default DashboardHeader;
