export const wrapper = {
  '& .bottomBuffer': {
    marginBottom: 24,
  },
};

export const headingLink = (theme) => ({
  color: theme.secondaryDark,
  fontSize: 16,
  lineHeight: 1.5,
  fontWeight: 500,
  transition: 'color .3s linear',
  '&:hover': {
    color: theme.primary,
  },
});

export const dashboardRowStyle = {
  display: 'flex',
  flexDirection: 'column',
};
