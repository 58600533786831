import { termsStyles } from './styles';

const TermsAndConditions = () => {
  return (
    <div>
      <h1 css={termsStyles}>Terms And Conditions page</h1>
    </div>
  );
};

export default TermsAndConditions;
