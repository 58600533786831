import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { isNull } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Skeleton, DefaultPagination, showApiError } from '../../../components/UI';
import { getAllExperts } from '../../../services/experts';
import { formatShortText } from '../../../utils/textFormat';
import teacherImg from '../../../assets/images/default-avatar.png';
import { wrapper, headerWrap, heading, expertBox, linkButton, skeletonStyle, footerWrap } from './styles';

const PAGE_SIZE = 10;

const Experts = () => {
  const [experts, setExperts] = useState(null);
  const { getText } = useTranslations();
  const pageIndexRef = useRef(0);

  useEffect(() => {
    fetchExperts();
  }, []);

  const fetchExperts = async (pageIndex) => {
    const [res, err] = await getAllExperts({
      pageIndex: pageIndex ?? 0,
      pageSize: PAGE_SIZE,
      sort: {
        sortField: 'id',
        sortOrder: 'desc',
      },
    });
    if (err) return showApiError(err);

    setExperts(res ?? []);
  };

  const handlePageChange = (pageIndex) => {
    window.scrollTo(0, 0);
    pageIndexRef.current = pageIndex;
    fetchExperts(pageIndex);
  };

  return (
    <div css={wrapper}>
      <div css={headerWrap}>
        <Row horizontalGap={12} noWrap container>
          <Col sm={12}>
            <h1 css={heading}>{getText('meetТheExperts')}</h1>
          </Col>
        </Row>
      </div>
      <Row horizontalGap={12} noWrap container>
        {!isNull(experts) ? (
          <>
            {experts?.data?.map((el) => (
              <Col sm={12} key={el.id}>
                <div css={expertBox(el?.picUrl ?? teacherImg)}>
                  <Row horizontalGap={0} noWrap>
                    <Col sm={6}>
                      <div className="course-image" />
                    </Col>
                    <Col sm={6}>
                      <div className="course-text-box">
                        <h2 className="title">
                          {el.firstName} {el.lastName}
                        </h2>
                        <h4 className="subtitle">{el.position}</h4>
                        <p className="text">{formatShortText(el?.description)}</p>
                        <Link
                          to={{
                            pathname: `/expert/${el.id}`,
                            state: { ...el },
                          }}
                          css={linkButton}>
                          {' '}
                          {getText('seeMore')}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </>
        ) : (
          <Col sm={12}>
            {Array(5)
              .fill()
              .map((el, index) => (
                <div key={index} css={skeletonStyle}>
                  <Skeleton height="315px" width="100%" />
                </div>
              ))}
          </Col>
        )}
      </Row>
      {experts?.data && experts?.data?.length && (
        <div css={footerWrap}>
          <DefaultPagination
            totalResults={experts?.total}
            pageIndex={0}
            pageSize={PAGE_SIZE}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Experts;
