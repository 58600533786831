import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Row, Icon } from '../../../UI';
import backUpThumbnail from '../../../../assets/images/backUpPicUrlThumnail.png';
import { ReactComponent as QuizIcn } from '../../../../assets/images/PlayCircleQuiz.svg';
import { ReactComponent as PdfIcn } from '../../../../assets/images/icon-pdf.svg';
import { durationInMinutes } from '../../../../utils/durationInMinutes';
import { courseDetails, startNowBtn, iconAndText, includesWrapper } from './styles';

const CourseDetailsHeader = ({ course }) => {
  const { getText } = useTranslations();

  return (
    <div className="header" css={courseDetails}>
      <Row container>
        <div className="details-wrapper">
          <div className="header-left">
            <h2 className="details-title">{course?.name}</h2>
            <p className="details-info">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate quaerat repudiandae ab vero error quos
              cumque dolorem quidem, consequuntur alias.
            </p>
            <div className="course-include" css={includesWrapper}>
              <h6>{getText('courseInclude')}</h6>
              <div css={iconAndText}>
                <PdfIcn />
                <p>e-book (PDF)</p>
              </div>
              <div css={iconAndText}>
                <Icon iconName="la la-file" />
                <p>{course?.steps.length} Lessons</p>
              </div>
              <div css={iconAndText}>
                <Icon iconName="la la-clock" />
                <p>{durationInMinutes(course?.totalVideoDurationInSeconds) ?? 'N/A'}</p>
              </div>
              <div css={iconAndText}>
                <QuizIcn />
                <p>Quiz for 80 points</p>
              </div>
            </div>
          </div>
          <div className="header-right">
            <img className="responsive-img" src={course?.picThumbUrl ?? backUpThumbnail} alt="" />
            <div className="info-and-button">
              <p>You&apos;ve already purchased this course</p>
              <Link to={`/course/${course?.id}`} css={startNowBtn}>
                Start now
              </Link>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default CourseDetailsHeader;
