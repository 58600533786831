import { useTranslations } from '@veraio/strank';
import { Row } from '../../../UI';
import { learnMockData } from './listConfig';
import { detailsDescription, whatYourWillLearn, learnTitle } from './styles';

const CourseDetailsDescription = ({ course }) => {
  const { getText } = useTranslations();

  return (
    <>
      <Row container>
        <div css={detailsDescription}>
          <h2 className="section-title">{getText('description')}</h2>
          <p>{course?.description}</p>
        </div>
      </Row>
      <Row container>
        <div css={whatYourWillLearn}>
          <h2 css={learnTitle} className="section-title">
            {getText('whatLearn')}
          </h2>
          <div className="items-wrapper">
            {learnMockData.map((el) => (
              <div className="check-and-text" key={el.id}>
                <i className="fas fa-check-circle" />
                <p>{el.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Row>
    </>
  );
};

export default CourseDetailsDescription;
