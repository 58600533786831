import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Paper, Form, FormInput, showApiError } from '../../../components/UI';
import { getIdentityProviderByEmail } from '../../../services/profiles';
import { identityProviderRedirectUrlPath } from '../../../enum';
import loginLogo from '../../../assets/images/logoLogin.svg';
import { container, formTitleContainer, loginTitle, detailStylesLogin, loginSubtitle, forgotStyles } from './styles';

const ForgotPassword = () => {
  const { getText } = useTranslations();

  const onSubmit = async (values) => {
    const [res, err] = await getIdentityProviderByEmail(values.email);
    if (err) {
      showApiError(err);
      return;
    }

    if (res) {
      const redirectUrl = `${res.rootUri}/${identityProviderRedirectUrlPath[res.provider]}`;
      window.location = redirectUrl;
    }
  };

  return (
    <div css={container}>
      <div css={formTitleContainer}>
        <Paper
          header={
            <div css={loginTitle}>
              <div css={loginTitle}>
                <div css={detailStylesLogin}>
                  <img src={loginLogo} alt="login-logo" />
                  <h5 css={loginSubtitle}>{getText('resetYourPassword')}</h5>
                </div>
              </div>
            </div>
          }>
          <Form
            onSubmit={onSubmit}
            submitButton={{
              children: getText('submit'),
              primary: true,
              css: { width: '100%', justifyContent: 'center' },
            }}
            preFilledValues={{
              email: '',
            }}>
            <FormInput id="email" label={getText('confirmEmailAddress')} placeholder={getText('yourEmail')} required />
          </Form>
        </Paper>
        <div css={forgotStyles}>
          <Link to="/login">{getText('logInInstead')}</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
