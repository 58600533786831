import { isNull } from 'lodash-es';
import { CourseCurriculum, InfoTabs } from '../../../components/Pages/Course';
import { useCourse } from './hook';
import { loader, coursePreviewContainer } from './style';

const Course = () => {
  const { course, isAvailable, step } = useCourse();

  return !isNull(course) ? (
    <section css={coursePreviewContainer}>
      <div className="course-preview-player-container">
        <h2>Video Player</h2>
        <InfoTabs course={course} isAvailable={isAvailable} />
      </div>
      <CourseCurriculum course={course} isAvailable={isAvailable} onChange={() => {}} step={step} />
    </section>
  ) : (
    <div css={loader}>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default Course;
