export const curriculumWrapper = (theme) => ({
  border: '1px solid #0000001F',
  borderRadius: 16,
  padding: '1em',
  width: '100%',
  '& h2': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    color: theme.newPrimary,
    textTransform: 'capitalize',
    marginBottom: '1em',
  },
  '& ul': {
    padding: 0,
  },
  '& .curriculum-item': {
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 0',
    color: theme.newGray10,
    '&:first-of-type': {
      color: theme.courseProgress,
    },
    '& .curriculum-item-icon': {
      marginRight: '1em',
      fontSize: '1.5rem',
    },
  },
  '& .item-duration': {
    fontSize: '0.875rem',
  },
});
