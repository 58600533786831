import { useEffect, useState } from 'react';
import { isNull, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, showApiError, Skeleton } from '../../../UI';
import CourseCard from '../../../Shared/CourseCard';
import { useAuth } from '../../../UI/Authenticate';
import { getMyCourses } from '../../../../services/courses';
import { noData, continueLearningStyles, heading } from './styles';

const ContinueLearning = () => {
  const { getText } = useTranslations();

  const [myCourses, setMyCourses] = useState(null);
  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
    },
  } = useAuth();

  useEffect(() => {
    fetchMyCourses();
  }, [currentAccount?.id]);

  const fetchMyCourses = async () => {
    const [res, err] = await getMyCourses({
      accountId,
      includeDetails: true,
      pageIndex: 0,
      pageSize: 3,
      sort: {
        sortField: 'id',
        sortOrder: 'desc',
      },
    });
    if (err) return showApiError(err);

    setMyCourses(res?.data ?? []);
  };

  return (
    <Row horizontalGap={12} noWrap container css={continueLearningStyles}>
      <Col xs={12}>
        {!isNull(myCourses) && !isEmpty(myCourses) && (
          <div css={heading}>
            <h2 className="section-title">{getText('continueLearning')}</h2>
          </div>
        )}
      </Col>
      {!isNull(myCourses) ? (
        !isEmpty(myCourses) ? (
          myCourses.map((el) => (
            <Col sm={4} key={el.id} className="bottomBuffer">
              <CourseCard data={el} />
            </Col>
          ))
        ) : (
          <div css={noData} />
        )
      ) : (
        Array(3)
          .fill()
          .map((el, index) => (
            <Col sm={4} key={index} className="bottomBuffer">
              <Skeleton height="340px" width="100%" />
            </Col>
          ))
      )}
    </Row>
  );
};

export default ContinueLearning;
