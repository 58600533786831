import { useState, forwardRef, useImperativeHandle } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useTranslations } from '@veraio/strank';
import { Modal, Button, showError } from '../../UI';
import { pagesCounter, buttonStyle, pdfViewWrap } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const options = {};

const PdfModal = forwardRef((props, ref) => {
  const { getText } = useTranslations();
  const [show, setShow] = useState(false);
  const [resource, setResource] = useState('');
  const [pagesCount, setPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useImperativeHandle(ref, () => ({
    setIsShow: () => setShow(!show),
    setSource: (source) => setResource(source),
  }));

  const handlePrevClick = () => (currentPage !== 1 ? setCurrentPage(currentPage - 1) : 1);
  const handleNextClick = () => (currentPage !== pagesCount ? setCurrentPage(currentPage + 1) : pagesCount);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setPagesCount(numPages);
    setLoading(false);
  };

  const handleLoadError = (error) => {
    setLoading(false);
    setShow(false);
    showError(error);
  };

  const handleCloseModal = () => {
    setLoading(true);
    setPagesCount(1);
    setCurrentPage(1);
    setResource('');
    setShow(false);
  };

  return (
    <Modal show={show} css={pdfViewWrap} onClose={handleCloseModal}>
      <div style={{ display: 'block' }}>
        <Document
          loading={
            <svg className="spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          }
          file={resource}
          options={options}
          onLoadSuccess={handleDocumentLoadSuccess}
          onLoadError={handleLoadError}
          onSourceError={handleLoadError}>
          <Page
            pageNumber={currentPage}
            loading={
              <svg className="spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
              </svg>
            }
          />
        </Document>

        {!loading && (
          <div>
            <Button onClick={handlePrevClick} css={buttonStyle} disabled={currentPage === 1}>
              {getText('previousPage')}
            </Button>
            <span css={pagesCounter}>
              {currentPage} of {pagesCount}
            </span>
            <Button onClick={handleNextClick} css={buttonStyle} disabled={currentPage === pagesCount}>
              {getText('nextPage')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
});

export default PdfModal;
