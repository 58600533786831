import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNaN } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { hourMinutesFormat } from '../../../utils';
import { ReactComponent as CourseCardIcn } from '../../../assets/images/icn-course-card.svg';
import {
  wrapper,
  header,
  courseThumb,
  body,
  percentWrap,
  percentLabel,
  courseCardHeading,
  cardSpansStyles,
} from './styles';

const CourseCard = (props) => {
  const { getText } = useTranslations();
  const {
    data: { id, name, picThumbUrl, accountCourse, totalVideoDurationInSeconds },
  } = props;
  const completedLessonProgress = accountCourse?.completedOn
    ? 100
    : Math.floor(Number(accountCourse.completedLessonProgress));

  return (
    <Link to={`/course-details/${id}`}>
      <div css={wrapper}>
        <div css={header}>
          {picThumbUrl ? <img src={picThumbUrl} css={courseThumb} alt={`course-card-${id}`} /> : <CourseCardIcn />}
        </div>
        <div css={body}>
          <h4 css={courseCardHeading}>{name}</h4>
          <div>
            <div css={percentWrap}>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${isNaN(completedLessonProgress) ? 0 : completedLessonProgress}%` }}
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
            <div css={cardSpansStyles}>
              <span css={percentLabel}>{`${isNaN(completedLessonProgress) ? 0 : completedLessonProgress}% ${getText(
                'completed',
              )}`}</span>
              <i className="fas fa-circle" />
              <p>
                <i className="far fa-clock" /> {hourMinutesFormat(totalVideoDurationInSeconds)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

CourseCard.propTypes = {
  data: PropTypes.any,
};

export default CourseCard;
