import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Button, Skeleton, showApiError } from '../../../components/UI';
import { getAllCategories } from '../../../services/categories';
import { formatShortText } from '../../../utils/textFormat';
import { ReactComponent as CourseCardIcn } from '../../../assets/images/icn-course-card.svg';
import { wrapper, headerWrap, heading, categoryBox, categoryBoxRow, buttonStyle, skeletonStyle } from './styles';

const CourseCategories = () => {
  const [categories, setCategories] = useState(null);
  const { getText } = useTranslations();
  const history = useHistory();

  const navigateToCategory = (route) => {
    history.push(`/courses/${route}`);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return showApiError(err);

    setCategories(res?.data ?? []);
  };

  return (
    <div css={wrapper}>
      <div css={headerWrap}>
        <Row horizontalGap={12} noWrap container>
          <Col sm={12}>
            <h1 css={heading}>{getText('courses')}</h1>
          </Col>
        </Row>
      </div>
      <Row horizontalGap={12} noWrap container>
        {!isNull(categories) ? (
          <>
            {categories?.map((el) => (
              <Col sm={12} key={el.id}>
                <div css={categoryBox}>
                  <Row horizontalGap={40} noWrap css={categoryBoxRow}>
                    <Col sm>
                      <h1 className="title">{el.name}</h1>
                      <p className="text">{formatShortText(el?.description)}</p>
                      <Button large onClick={() => navigateToCategory(el?.id)} css={buttonStyle}>
                        {getText('getStarted')}
                      </Button>
                    </Col>
                    <Col sm="auto">
                      <div className="category-image">
                        <CourseCardIcn />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </>
        ) : (
          <Col sm={12}>
            {Array(5)
              .fill()
              .map((el, index) => (
                <div key={index} css={skeletonStyle}>
                  <Skeleton height="280px" width="100%" />
                </div>
              ))}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CourseCategories;
