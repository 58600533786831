import { useEffect, useState, useRef } from 'react';
import { isNull, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, showApiError, Skeleton, Dropdown } from '../../UI';
import CourseCard from '../../Shared/CourseCard';
import MightLikeCourses from '../Course/MightLikeCourses/index';
import { useAuth } from '../../UI/Authenticate';
import { getMyCourses } from '../../../services/courses';
import noDataBgImage from '../../../assets/images/myCoursesNoData.svg';
import {
  heading,
  bigHeading,
  noData,
  myCoursesHeaderWrap,
  myCourseCardRow,
  filterSearchDropdown,
  filterAndHeadingWrapper,
} from './styles';

const MyCourses = () => {
  const { getText } = useTranslations();
  const [myCourses, setMyCourses] = useState(null);

  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
    },
  } = useAuth();

  const filterOptions = [
    { label: getText('completed'), value: 'completed' },
    { label: getText('inProgress'), value: 'inProgress' },
  ];

  const filterValuesRef = useRef({
    sort: filterOptions[0].value,
  });

  useEffect(() => {
    fetchMyCourses();
  }, [currentAccount?.id]);

  const fetchMyCourses = async () => {
    const [res, err] = await getMyCourses({ accountId, includeDetails: true });
    if (err) return showApiError(err);

    setMyCourses(res?.data ?? []);
  };

  const handleSortChange = ({ value }) => {
    filterValuesRef.current.sort = value;
  };

  return (
    <>
      <section className="header">
        <Row horizontalGap={12} noWrap container css={myCoursesHeaderWrap}>
          <Col>
            <h2 css={heading}>{getText('courses')}</h2>
          </Col>
          <Row horizontalGap={12} noWrap container>
            <Col>
              <div css={bigHeading}>
                <h3>{getText('myCourses')}</h3>
              </div>
            </Col>
          </Row>
        </Row>
      </section>
      <Row horizontalGap={12} noWrap css={myCourseCardRow}>
        <Row container css={filterAndHeadingWrapper}>
          <Col>
            <h2 className="section-title">{`${getText('youHave')} ${myCourses?.length} ${
              myCourses?.length === 1 ? getText('course') : getText('courses')
            }`}</h2>
          </Col>
          <Dropdown
            options={filterOptions}
            autoWidth
            value={filterOptions[0]}
            css={filterSearchDropdown}
            onChange={handleSortChange}
          />
        </Row>
        {!isNull(myCourses) ? (
          <Row
            horizontalGap={12}
            noWrap
            container
            css={{
              justifyContent: isEmpty(myCourses) ? 'center' : 'flex-start',
              margin: isEmpty(myCourses) ? '3em 0' : '0 -12px',
            }}>
            {!isEmpty(myCourses) ? (
              myCourses?.map((el) => (
                <Col xs={12} sm={6} md={4} lg={3} key={el.id} className="bottomBuffer">
                  <CourseCard data={el} />
                </Col>
              ))
            ) : (
              <div css={noData}>
                <img src={noDataBgImage} alt="" />
              </div>
            )}
          </Row>
        ) : (
          <Row horizontalGap={12} noWrap container>
            {Array(4)
              .fill()
              .map((el, index) => (
                <Col xs={12} sm={6} md={4} lg={3} key={index} className="bottomBuffer">
                  <Skeleton height="340px" width="100%" />
                </Col>
              ))}
          </Row>
        )}
      </Row>
      <Row>
        <MightLikeCourses />
      </Row>
    </>
  );
};

export default MyCourses;
