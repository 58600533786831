export const noData = {
  display: 'none',
};

export const continueLearningStyles = {
  display: 'flex',
  flexDirection: 'column',
};

export const heading = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
