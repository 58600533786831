import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Row, Skeleton, showApiError } from '../../UI';
import categoryIcon from '../../../assets/images/categoryIconExample.svg';
import { getAllCategories } from '../../../services/categories';
import { recommendedWrapper, categoriesCardsWrapper, categoryCardStyle, categoriesWrapperRow } from './styles';

const RecommendedCategories = () => {
  const { getText } = useTranslations();
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setIsLoading(true);
    const [res, err] = await getAllCategories();
    if (err) {
      showApiError(err);
      setIsLoading(false);
      return;
    }

    setCategories(res?.data.slice(0, 3) ?? []);
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div css={recommendedWrapper}>
        <Row container>
          <h2 className="section-title">{getText('recommendedCategories')}</h2>
        </Row>
        <Row container css={categoriesWrapperRow}>
          {Array(4)
            .fill()
            .map((_, index) => (
              <div key={index} css={categoriesCardsWrapper}>
                <Skeleton height="180px" width="100%" />
              </div>
            ))}
        </Row>
      </div>
    );
  }

  return (
    <div css={recommendedWrapper}>
      <Row container>
        <h2 className="section-title">{getText('recommendedCategories')}</h2>
      </Row>
      <Row container css={categoriesWrapperRow}>
        {categories?.map((category) => (
          <div css={categoriesCardsWrapper} key={category.id}>
            <div css={categoryCardStyle}>
              <img src={category.icon || categoryIcon} alt={category.name} />
              <h5>{category.name}</h5>
            </div>
          </div>
        ))}
      </Row>
    </div>
  );
};

export default RecommendedCategories;
