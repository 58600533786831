export const detailsDescription = (theme) => ({
  width: '100%',
  '& h2': {
    color: theme.newPrimary,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    marginBottom: '1em',
  },
  margin: '3em 0',
});

export const whatYourWillLearn = (theme) => ({
  padding: '2em',
  backgroundColor: theme.newGray9,
  marginBottom: '3em',
  borderRadius: 8,
  width: '100%',
  '& .items-wrapper': {
    display: 'flex',
    gap: '1em',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  '& .check-and-text': {
    flex: '0 0 45%',
    display: 'flex',
    gap: '1em',
    alignItems: 'center',
    marginBottom: '1em',
  },
  '& .fa-check-circle': {
    color: theme.courseProgress,
    fontSize: '1.5rem',
  },
});

export const learnTitle = {
  marginTop: 0,
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
};
