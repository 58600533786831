import { Row, Col } from '../../../components/UI';
import DashboardHeader from '../../../components/Pages/Dashboard/DashboardHeader';
import ContinueLearning from '../../../components/Pages/Dashboard/ContinueLearning';
import Recommended from '../../../components/Shared/Recommended';
import RecommendedCategories from '../../../components/Shared/RecommendedCategories';
import { wrapper, dashboardRowStyle } from './styles';

const Dashboard = () => {
  return (
    <>
      <section className="header">
        <Row container css={dashboardRowStyle}>
          <Col>
            <DashboardHeader />
          </Col>
        </Row>
      </section>
      <section css={wrapper}>
        <Row horizontalGap={12} noWrap container>
          <Col sm={9}>
            <ContinueLearning />
          </Col>
        </Row>
        <Row container>
          <Col>
            <Recommended />
          </Col>
        </Row>
        <RecommendedCategories />
      </section>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
