import { postReq, getReq } from './axios/makeRequest';
import { addAccountModel, getAllFriendsParams } from './models/accounts';

const baseUrl = `${apiUrls.oneAcademy}/accounts`;

export const postNewAcademyAccount = (values) => postReq(`${baseUrl}/new`, addAccountModel(values));

export const getNewAcademyAccount = async (id) => getReq(`${baseUrl}/external-account-id/${id}`);

export const getAllFriends = (accountId, options = {}) => {
  const params = getAllFriendsParams(options);
  return getReq(`${baseUrl}/${accountId}/friends-list?${params}`);
};

export const getAccountAchievements = async (id) => getReq(`${baseUrl}/${id}/accomplishments`);
