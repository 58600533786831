import { Row } from '../../../UI';
import { useTranslations } from '@veraio/strank';
import { durationInMinutes } from '../../../../utils/durationInMinutes';
import { curriculumWrapper } from './styles';

const CourseDetailsCurriculum = ({ course }) => {
  const { getText } = useTranslations();

  return (
    <Row container>
      <div css={curriculumWrapper}>
        <h2>{getText('courseCurriculum')}</h2>
        <ul>
          {course?.steps?.map((el, index) => (
            <li key={el.id} className="curriculum-item">
              <div>
                <span className="curriculum-item-icon">
                  {index === 0 ? <i className="far fa-play-circle" /> : <i className="fas fa-lock" />}
                </span>
                {el.title}
              </div>
              <span className="item-duration">{durationInMinutes(course?.totalVideoDurationInSeconds)}</span>
            </li>
          ))}
        </ul>
      </div>
    </Row>
  );
};

export default CourseDetailsCurriculum;
