export const icon = [
  {
    icon: 'fab fa-twitter',
    path: '/twitter',
  },
  {
    icon: 'fab fa-facebook-f',
    path: '/facebook',
  },
  {
    icon: 'fab fa-instagram',
    path: '/instagram',
  },
];

export const productElements = (getText) => [
  {
    title: getText('allCourses'),
    path: '/courses',
  },
  {
    title: getText('myCourses'),
    path: '/courses',
  },
];
