import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Tabs, Tab, Row, Col } from '../../../UI';
// import QuizResults from '../QuizResults';
import MaterialsList from './MaterialsList';
// import AuthorBox from '../AuthorBox';
import { wrapper, tabContainer, aboutTab } from './styles';

const InfoTabs = (props) => {
  const { course } = props;
  const { getText } = useTranslations();

  const aboutContent = () => (
    <div css={aboutTab}>
      <h6>{getText('courseOverview')}</h6>
      <p>{course?.description}</p>
    </div>
  );

  return (
    <div css={wrapper}>
      <Row horizontalGap={12} noWrap>
        <Col className="bottomBuffer">
          <Tabs routeMatch="/course/:id" startingRoute={`/course/${course?.id}`} tabsContentClass={tabContainer}>
            <Tab label={getText('overview')} url="/about" component={() => aboutContent()} />
            <Tab label={getText('materials')} url="/resources" component={() => <MaterialsList course={course} />} />
            <Tab
              label={getText('certificate')}
              url="/certificate"
              // component={() => <QuizResults course={course} />}
            />
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

InfoTabs.propTypes = {
  course: PropTypes.object,
  isAvailable: PropTypes.bool,
};

export default InfoTabs;
