import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import { isNull } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Skeleton, showApiError } from '../../UI';
import { useAuth } from '../../UI/Authenticate';
import CourseCategoryCard from '../../../components/Pages/Course/CourseCategoryCard';
import { getAllCourses } from '../../../services/courses';
import { wrapper, recommendedSeeAllBtn, customArrowStyle, titleAndArrows } from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

const Recommended = (props) => {
  const { categoryId = null } = props;
  const { getText } = useTranslations();
  const [courses, setCourses] = useState(null);
  const carouselRef = useRef();

  const {
    user: {
      academyAccount: { id: accountId },
      myPackages,
      currentAccount,
      profile,
    },
  } = useAuth();

  useEffect(() => {
    fetchCourses();
  }, [categoryId, currentAccount?.id]);

  const fetchCourses = async () => {
    const [res, err] = await getAllCourses({
      notAccountId: accountId,
      categoryId,
      includeDetails: true,
      randomize: true,
    });
    if (err) return showApiError(err);

    const filteredCourses = res?.data
      ?.slice(0, 4)
      .filter(
        (item) =>
          !myPackages.some((elem) =>
            item.catalogueItems.map((el) => el?.catalogueItemId).includes(elem?.catalogueItemId),
          ),
      );

    setCourses(filteredCourses);
  };

  const CustomArrow = ({ onClick, direction }) => (
    <button onClick={onClick} css={customArrowStyle}>
      {direction === 'left' ? <i className="fas fa-chevron-left" /> : <i className="fas fa-chevron-right" />}
    </button>
  );

  return (
    <section css={wrapper}>
      <div css={titleAndArrows}>
        <h2 className="section-title">{`${getText('recommendedCoursesForYou')}, ${profile?.firstName}`}</h2>
        {!!courses && (
          <div className="arrows-container">
            <CustomArrow direction="left" onClick={() => carouselRef.current.previous()} />
            <CustomArrow direction="right" onClick={() => carouselRef.current.next()} />
          </div>
        )}
      </div>
      {!isNull(courses) ? (
        <>
          <Carousel
            ref={(ref) => {
              carouselRef.current = ref;
            }}
            infinite
            autoPlay
            arrows={false}
            autoPlaySpeed={3000}
            responsive={responsive}
            containerClass="carousel-container">
            {courses.map((course) => (
              <div key={course.id} css={{ padding: '0 0.5em' }}>
                <CourseCategoryCard course={course} />
              </div>
            ))}
          </Carousel>
          <Link to={'/courses'} css={recommendedSeeAllBtn}>
            {getText('viewAll')}
          </Link>
        </>
      ) : (
        <Row horizontalGap={12} noWrap container>
          <Col sm={9} className="bottomBuffer">
            <Skeleton height="670px" width="100%" />
          </Col>
          <Col sm={3} className="bottomBuffer">
            <Skeleton height="323px" width="100%" />
            <div className="bottomBuffer" />
            <Skeleton height="323px" width="100%" />
          </Col>
        </Row>
      )}
    </section>
  );
};

Recommended.propTypes = {
  categoryId: PropTypes.any,
};

export default Recommended;
