import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { softLogout } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { useAuth } from '../Authenticate';
import { Row, Col } from '../Grid';
import NavLinks from './NavLinks';
import Icon from '../Icon';
import AccountsList from '../../../components/UI/NavBar/AccountsList';
// import AccountDropdown from './AccountDropdown';
import { privateLinks } from './definitions';
import navLogo from '../../../assets/images/navBarLogo.svg';
import {
  navBarContainer,
  appAvatarIcon,
  hamburgerStyle,
  hamburgerDropdownStyles,
  linkStyles,
  signOutMobile,
  viewProfileMobile,
  profileIconStyles,
  profilePicName,
  viewProfileDropdown,
} from './styles';

const NavBar = () => {
  const { isAuthenticated } = useAuth();
  const { getText } = useTranslations();
  const {
    user: { profile, currentAccount },
  } = useAuth();
  const [showList, seShowList] = useState(false);
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const switchRef = useRef();

  useEffect(() => {
    if (!showList && switchRef.current) switchRef.current.focus();
  }, [showList]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) setOpenHamburgerMenu(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleBack = () => {
    seShowList(false);
  };

  const handleHamburgerClick = () => {
    setOpenHamburgerMenu((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.clear();
    softLogout();
  };
  return (
    <>
      <nav css={navBarContainer}>
        <Row container align="center" noWrap>
          <Col lg="auto">
            <Link to={isAuthenticated ? '/dashboard' : '/'}>
              <img src={navLogo} css={appAvatarIcon} alt="Logo" />
            </Link>
          </Col>
          <Col lg>
            <NavLinks />
          </Col>
          <div role="button" tabIndex={0} css={hamburgerStyle} onClick={handleHamburgerClick}>
            <Icon iconName="la la-bars" />
          </div>
          <Row container>
            {openHamburgerMenu && (
              <ul css={hamburgerDropdownStyles}>
                <li>
                  <div css={viewProfileDropdown}>
                    {showList ? (
                      <AccountsList onBack={handleBack} />
                    ) : (
                      <div css={viewProfileMobile}>
                        <div className="wrapper">
                          <div css={profilePicName}>
                            {profile?.picThumbnailUrl ? (
                              <img src={profile.picThumbnailUrl} alt="User Profile" css={profileIconStyles} />
                            ) : (
                              <Icon iconName="la la-user" css={profileIconStyles} />
                            )}
                            <h3 className="title">{profile?.firstName}</h3>
                          </div>
                          <span className="label">#{currentAccount?.id}</span>
                        </div>
                        <div
                          ref={switchRef}
                          className="swap-icon"
                          onClick={() => seShowList(true)}
                          role="button"
                          tabIndex={0}>
                          <Icon iconName="la la-exchange" />
                        </div>
                      </div>
                    )}
                  </div>
                </li>
                {privateLinks(getText()).left.map((el) => (
                  <li key={el.path}>
                    <Link to={el.path} css={linkStyles}>
                      {el.label}
                    </Link>
                  </li>
                ))}
                {privateLinks(getText()).right.map((el) => (
                  <li key={el.path}>
                    <Link to={el.path} css={linkStyles(true)}>
                      {el.label}
                    </Link>
                  </li>
                ))}
                <li>
                  <div role="button" tabIndex={0} onClick={handleLogout} css={signOutMobile}>
                    <Icon iconName="la la-sign-out" />
                    <h4 className="title-sign-out">{getText('signOut')}</h4>
                  </div>
                </li>
              </ul>
            )}
          </Row>
        </Row>
      </nav>
      {/* {isAuthenticated && <AccountDropdown />} */}
    </>
  );
};

export default NavBar;
