import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { useAuth } from '../Authenticate';
import Icon from '../Icon';
import SearchBar from '../SearchBar';
import { showApiError } from '../Notifications';
import { switchAccount } from '../../../services/profiles';
import { accountsList, accountDropdownItem, accountLoaderWrap, accountChangeLoader } from './styles';

const AccountsList = ({ onBack }) => {
  const { user, changeAccount } = useAuth();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState(user?.profile?.accounts ?? []);
  const searchRef = useRef();

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  const handleAccountChange = async (account) => {
    setLoading(true);
    const [, err] = await switchAccount(account.id);
    setLoading(false);
    if (err) return showApiError(err);

    changeAccount(account);
  };

  const handleFilterChange = debounce((value) => {
    setAccounts(
      value?.length < 3
        ? user?.profile?.accounts
        : user?.profile?.accounts?.filter((el) => el?.nickName?.toLowerCase().indexOf(value.toLowerCase()) !== -1),
    );
  }, 500);

  return (
    <div css={accountsList}>
      <div css={accountLoaderWrap(loading)}>
        <svg className="spinner" viewBox="0 0 24 24" css={accountChangeLoader}>
          <circle className="path" cx="12" cy="12" r="9" fill="none" strokeWidth="3" />
        </svg>
      </div>
      <div className="header">
        <div className="back-button" onClick={onBack} role="button" tabIndex={0}>
          <Icon iconName="la la-angle-left" />
          <p>Back</p>
        </div>
        <SearchBar
          inputRef={searchRef}
          placeholder="Search by nickname"
          onSubmit={handleFilterChange}
          className="nickname-search"
        />
      </div>
      <div className="body">
        {accounts?.map((el) => (
          <div
            key={el.id}
            className="item"
            onClick={() => handleAccountChange(el)}
            role="button"
            tabIndex={0}
            css={accountDropdownItem(user?.currentAccount?.id === el?.id)}>
            {el?.nickName ? (
              <>
                <p className="item-id">#{el?.nickName}</p>
                <p className="item-name">{el?.id ?? '-----------'}</p>
              </>
            ) : (
              <p className="item-name">{el?.id ?? '-----------'}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

AccountsList.propTypes = {
  onBack: PropTypes.func,
};

export default AccountsList;
