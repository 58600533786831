export const courseDetails = (theme) => ({
  color: theme.white,
  '& .details-wrapper': {
    display: 'flex',
    gap: '2em',
    justifyContent: 'space-between',
  },
  '& .header-left, .header-right': {
    flex: '0 0 50%',
  },
  '& .header-right': {
    padding: '1.5em',
    paddingRight: '2em',
    '& img': {
      width: '100%',
    },
  },
  '& .details-title, .details-info': {
    marginBottom: '1em',
  },
  '& .info-and-button': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1em',
    alignItems: 'center',
    margin: '1em 0',
    borderBottom: '1px solid #FFFFFF29',
    paddingBottom: '1em',
  },
});

export const startNowBtn = (theme) => ({
  backgroundColor: theme.newPrimaryBtn,
  padding: '0.625em 1em',
  borderRadius: 4,
  '&:hover': {
    backgroundColor: theme.warning,
  },
});

export const iconAndText = (theme) => ({
  display: 'flex',
  gap: '0.5em',
  padding: '1em 0',
  borderBottom: '1px solid #FFFFFF1F',
  alignItems: 'center',
  '& i': {
    color: theme.white,
  },
});

export const includesWrapper = (theme) => ({
  fontFamily: 'Roboto, sans-serif;',
  fontWeight: 300,
  marginTop: '4em',
  '& h6': {
    fontWeight: 500,
  },
  padding: '1.5em',
  border: `1px solid ${theme.courseProgress}`,
  borderRadius: 4,
  boxShadow: '0px 4px 18px 0px #00000029',
});
