export const courseBox = (theme) => ({
  backgroundColor: theme.white,
  boxShadow: '0px 1px 8px 0px #00000029',
  height: '300px',
  marginBottom: '1em',
  '& .row-wrapper': {
    height: '100%',
    flexWrap: 'wrap',
  },
  '& .img-wrapper': {
    display: 'flex',
    padding: '1em',
    maxHeight: '100%',
    height: '100%',
    '& img': {
      maxWidth: '100%',
      objectFit: 'cover',
      height: 'auto',
    },
  },
  '& .course-category': {
    color: theme.newGray2,
    marginBottom: '1em',
    '& > span': {
      marginRight: '0.5em',
    },
  },
  '& .course-text-box': {
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '1em',
    maxWidth: '100%',
    '& .title': {
      marginBottom: 16,
      fontSize: '1.0625rem',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      color: theme.newPrimary,
    },
    '& .text': {
      fontSize: '0.75rem',
      color: theme.newGray6,
      wordBreak: 'break-all',
      lineHeight: 1.5,
      marginBottom: 16,
    },
  },
  '@media (max-width: 500px)': {
    '.row-wrapper': {
      flexDirection: 'column',
      height: '100%',
    },
    '& .img-wrapper': {
      padding: '1em',
      flex: '0 0 auto',
      width: '100%',
      height: '150px',
      overflow: 'hidden',
      marginBottom: '1em',
      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      },
    },
    '& .course-text-box': {
      flex: '1 1 auto',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '0 1em 1em 1em',
      maxWidth: '100%',
      maxHeight: '100%',
      '& .title': {
        fontSize: '0.875rem',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        color: theme.newPrimary,
      },
      '& .text': {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.newGray6,
        wordBreak: 'break-word',
        lineHeight: 1.5,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

export const buttonStyle = (theme) => ({
  padding: '0.5em 0.75em',
  color: theme.white,
  backgroundColor: theme.newPrimary,
  borderRadius: 4,
  fontSize: '0.75rem',
  lineHeight: '1.1375rem',
});

export const skeletonStyle = {
  marginBottom: 40,
};

export const cardWrapper = {
  display: 'flex',
};

export const btnAndDurationCourseCard = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1em',
  width: '100%',
  '& p': {
    fontSize: '0.6875rem',
    color: theme.newGray7,
  },
});

export const customCol = {
  flex: '2 0 0',
};
