export const videoItemWrap = (active, isFree) => (theme) => ({
  color: active ? theme.courseProgress : theme.newGray3,
  display: 'flex',
  alignItems: 'center',
  opacity: isFree ? 1 : 0.7,
  '& .icon': {
    marginRight: '1.5em',
    opacity: active ? 1 : 0.8,
    width: 16,
    '& > i': {
      fontSize: '1rem',
    },
  },
  '& p': {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    opacity: active ? 1 : 0.8,
  },
});

export const asideStyle = (theme) => ({
  flex: '4 0 0',
  backgroundColor: theme.white,
  // maxHeight: '100%',

  '& .course-curriculum-list': {
    padding: 0,

    '& .course-curriculum-item': {
      border: 'none',
      color: theme.black,
    },
  },
});
