import { useTranslations } from '@veraio/strank';
import { Button } from '../../UI';
import logoFooterCard from '../../../assets/images/levelBadge.svg';
import { footerCardStyles, logoAndTextWrapper, footerCardBtn, footerCardTitleAndDescription } from './styles';

const FooterCard = () => {
  const { getText } = useTranslations();

  return (
    <div css={footerCardStyles}>
      <div css={logoAndTextWrapper}>
        <img src={logoFooterCard} alt="footer-logo" />
        <div>
          <h5 css={footerCardTitleAndDescription}>Level 1</h5>
          <p css={footerCardTitleAndDescription}>You have earned 24 points</p>
        </div>
      </div>
      <Button clear small css={footerCardBtn}>
        {getText('viewDetails')}&nbsp; <i className="fas fa-chevron-right" />
      </Button>
    </div>
  );
};

export default FooterCard;
