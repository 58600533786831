export const wrapper = {
  position: 'relative',
  '& .bottomBuffer': {
    marginBottom: 24,
  },
};

export const recommendedSeeAllBtn = (theme) => ({
  color: theme.newPrimary,
  backgroundColor: hexToRgba(theme.newPrimary, 0.12),
  borderRadius: 34,
  padding: '0.5em 0.75em',
  fontWeight: 500,
  fontSize: '0.75rem',
  '&:hover': {
    color: theme.white,
  },
});

const hexToRgba = (hex, opacity) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const navButtonStyle = {
  padding: '8px 16px',
  borderRadius: '8px',
  backgroundColor: '#0056b3',
  color: '#fff',
  border: 'none',
  cursor: 'pointer',
  '&:disabled': {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  },
};

export const customArrowStyle = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.newPrimary,
  border: 'none',
  cursor: 'pointer',
  color: theme.white,
  borderRadius: '50%',
  width: '30px',
  height: '30px',
});

export const titleAndArrows = {
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  '& .arrows-container': {
    display: 'flex',
    gap: '0.5em',
  },
};
