export const learnMockData = [
  {
    id: 1,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, incidunt.',
  },
  {
    id: 2,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, incidunt.',
  },
  {
    id: 3,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, incidunt.',
  },
  {
    id: 4,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, incidunt.',
  },
  {
    id: 5,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, incidunt.',
  },
  {
    id: 6,
    description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi, incidunt.',
  },
];
