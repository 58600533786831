import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import UserDropdown from './UserDropdown';
import { useAuth } from '../Authenticate';
import { privateLinks } from './definitions';
import { sidesContainer, linkStyles, navContainer } from './styles';

const NavLinks = () => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useAuth();

  return (
    <div css={navContainer}>
      <div css={sidesContainer}>
        {isAuthenticated &&
          privateLinks(getText).left.map((el) => (
            <Link to={el.path} key={el.path} css={linkStyles}>
              {el.label}
            </Link>
          ))}
      </div>
      <div css={sidesContainer}>
        {isAuthenticated && (
          <>
            {privateLinks(getText).right.map((el) => (
              <Link to={el.path} key={el.path} css={linkStyles(true)}>
                {el.label}
              </Link>
            ))}
            <UserDropdown />
          </>
        )}
      </div>
    </div>
  );
};

export default NavLinks;
