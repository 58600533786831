import { Route } from 'react-router-dom';
import AllCourses from './AllCourses';
import FriendsRankList from './FriendsRankList';
import Course from './Course';
import CourseCategories from './CourseCategories';
import MyCourses from './MyCourses';
import Dashboard from './Dashboard';
import Experts from './Experts';
import Expert from './Expert';
import Achievements from './Achievements';
import CourseDetails from './CourseDetails';

export default [
  {
    exact: true,
    path: '/dashboard',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/my-courses',
    component: MyCourses,
  },
  {
    path: '/course/:id',
    component: Course,
  },
  {
    exact: true,
    path: '/courses',
    component: AllCourses,
  },
  {
    path: '/friends-rank-list',
    component: FriendsRankList,
  },
  {
    exact: true,
    path: '/course-categories',
    component: CourseCategories,
  },
  {
    exact: true,
    path: '/experts',
    component: Experts,
  },
  {
    path: '/expert/:id',
    component: Expert,
  },
  {
    path: '/achievements',
    component: Achievements,
  },
  {
    path: '/course-details/:id',
    component: CourseDetails,
  },
].map((route) => <Route key={route.path} {...route} />);
