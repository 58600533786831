import MyCourses from '../../../components/Pages/MyCourses';

const AllCourses = () => (
  <>
    <MyCourses />
  </>
);

AllCourses.propTypes = {};

export default AllCourses;
