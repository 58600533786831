import { Route } from 'react-router-dom';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';

export default [
  {
    exact: true,
    path: '/login',
    component: Login,
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
].map((route) => <Route key={route.path} {...route} />);
