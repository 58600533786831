import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import avatarImg from '../../../assets/images/default-avatar.png';
import { ReactComponent as CourseCardIcn } from '../../../assets/images/icn-course-card.svg';
import { ReactComponent as ClockIcn } from '../../../assets/images/icn-lesson-clock.svg';
// import { ReactComponent as UserIcn } from '../../../assets/images/icn-lesson-user.svg';
import { ReactComponent as MarkIcn } from '../../../assets/images/icn-mark.svg';
import { formatShortText } from '../../../utils/textFormat';
import { hourMinutesFormat } from '../../../utils';
import { wrapper, thumbWrap, body, infoSection, bodyText, bodyFooter } from './styles';

const LessonCard = (props) => {
  const { getText } = useTranslations();
  const { lesson, author, big } = props;

  let expert = author;

  if (lesson?.expert) expert = lesson?.expert;

  return (
    <Link to={`/course/${lesson?.id}`}>
      <div css={wrapper}>
        <div
          css={thumbWrap(big)}
          style={{
            backgroundImage: `url(${big ? lesson?.pictureUrl : lesson?.picThumbUrl})`,
          }}>
          {!lesson?.picThumbUrl && !lesson?.pictureUrl && <CourseCardIcn />}
          {lesson?.category && <span className="thumb-label">{lesson?.category?.name}</span>}
        </div>
        <div css={body(big)}>
          <div>
            <div css={infoSection(big)}>
              {/* <div>
              <UserIcn />
              <p>
                {`${lesson?.peopleWatching} ${
                  big ? getText('peopleWatching') : ''
                }`}
              </p>
            </div> */}
              <div>
                <ClockIcn />
                <p>
                  {big ? `${lesson?.courseLessonsCount} ${getText('lessons')},` : ''}{' '}
                  {hourMinutesFormat(lesson?.totalVideoDurationInSeconds)}
                </p>
              </div>
            </div>
            <div css={bodyText(big)}>
              <h2>{lesson?.name}</h2>
              {big && lesson?.description && <p>{formatShortText(lesson?.description)}</p>}
            </div>
          </div>
          <div css={bodyFooter(big, expert)}>
            {expert && (
              <div className="authorWrap">
                {big && (
                  <div
                    className="authorAvatar"
                    style={{
                      backgroundImage: `url(${expert?.picUrl ?? avatarImg})`,
                    }}
                  />
                )}
                {expert && (
                  <div>
                    <p className="authorName">
                      {expert?.firstName} {expert?.lastName}
                    </p>
                    <p className="authorPosition">{expert?.position}</p>
                  </div>
                )}
              </div>
            )}
            <MarkIcn />
          </div>
        </div>
      </div>
    </Link>
  );
};

LessonCard.propTypes = {
  lesson: PropTypes.any,
  author: PropTypes.any,
  big: PropTypes.bool,
};

export default LessonCard;
