import { keyframes } from '@emotion/react';

// Form styles start
export const formContainer = {
  width: '100%',
  marginBottom: 60,
};

export const formFieldsContainer = {
  marginBottom: '0.5em',
};
// Form styles end

// Input styles start (base one)
export const inputContainer = (isHorizontal) => ({
  display: 'flex',
  flexDirection: isHorizontal ? 'row' : 'column',
  alignItems: 'flex-start',
  justifyContent: isHorizontal ? 'space-between' : 'flex-start',
  position: 'relative',
  width: '100%',
  marginBottom: '0.5em',
  outline: 'none',
});

export const horizontalContainer = {
  flex: 1,
  position: 'relative',
};

export const inputLabel = (isHorizontal) => (theme) => ({
  fontWeight: 600,
  opacity: 1,
  color: theme.white,
  fontSize: '0.75rem',
  margin: `
      ${isHorizontal ? '12px' : 0}
      ${isHorizontal ? '24px' : 0}
      ${isHorizontal ? 0 : '8px'}
      0`,
});

export const inputField = (hasError, hasText) => (theme) =>
  [
    {
      fontWeight: 400,
      padding: 10,
      lineHeight: 'normal',
      width: '100%',
      border: '1px solid rgba(255, 255, 255, 0.12)',
      borderRadius: '3px',
      background: theme.newPrimary,
      color: theme.white,
      '::placeholder': {
        color: theme.white,
        opacity: 0.7,
      },
    },
    hasError
      ? {
          border: `1px solid ${theme.error}`,
        }
      : hasText
      ? {
          border: `1px solid ${theme.success}`,
          borderRadius: 3,
          background: theme.newPrimary,
        }
      : {},
  ];
const helpTextAnimation = keyframes`
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 24px;
    opacity: 1;
  }
`;

export const inputHelpContainer = (hasError) => (theme) => ({
  fontSize: '13px',
  lineHeight: 1.54,
  color: hasError ? theme.errorDark : theme.successDark,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 6,
  fontWeight: 400,
  animation: `${helpTextAnimation} 400ms ease-out`,
});

export const inputHelpIcon = (hasError) => (theme) => ({
  padding: hasError ? 4 : 6,
  borderRadius: '50%',
  marginRight: 6,
  backgroundColor: hasError ? theme.errorLight : theme.successLight,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > img': {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
});

export const inputFieldRightIcon = (isPasswordType) => ({
  position: 'absolute',
  right: isPasswordType ? 38 : 8,
  bottom: 26,
});

export const inputFieldVisibilityIcon = {
  position: 'absolute',
  right: 8,
  bottom: 26,
};
// Input styles end (base one)

// Input styles start (dynamic one)
export const dynamicInputContainer = (isReadOnly) => (theme) => ({
  borderRadius: 8,
  paddingLeft: 8,
  '&:hover': {
    backgroundColor: isReadOnly ? 'transparent' : theme.whiteGray,
  },
  '@media (max-width: 991px)': {
    marginTop: 8,
  },
});

export const dynamicInputError = (theme) => ({
  fontSize: '12px',
  lineHeight: 1,
  color: theme.errorDark,
});

export const dynamicInputErrorAdd = (theme) => [
  dynamicInputError(theme),
  {
    paddingLeft: 12,
  },
];

export const dynamicInputAddLabel = (hasError, isDisabled, required) => (theme) => ({
  color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : theme.primary,
  fontSize: 16,
  lineHeight: hasError ? '17px' : '21px',
  padding: required ? (hasError ? '5px 0' : '8px 0 10px') : hasError ? '8px 0 0' : '8px 0 12px',
  outline: 'none',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
});

export const dynamicInputLabel = (hasError, isDisabled) => (theme) => ({
  color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : theme.textLightSecondary,
  padding: hasError ? '5px 0 5px 14px' : '10px 0 12px 14px',
  '@media (max-width: 991px)': {
    padding: '10px 0',
  },
});

export const dynamicInputValue = (hasError, isDisabled, isReadOnly) => (theme) => ({
  color: hasError
    ? theme.errorDark
    : isDisabled
    ? theme.textLightDisabled
    : isReadOnly
    ? theme.textLightSecondary
    : theme.textLightPrimary,
  display: 'flex',
  alignItems: 'center',
  padding: '11px 9px',
  '@media (max-width: 991px)': {
    padding: '11px 9px 11px 0',
  },
});

export const dynamicInputEditIcon = (theme) => ({
  opacity: 0,
  fontSize: 20,
  padding: 3,
  borderRadius: 6,
  marginRight: 6,
  border: `1px solid ${theme.primary}`,
});

export const dynamicDropdownClearIcon = {
  opacity: 0,
  fontSize: 20,
  padding: 3,
  marginRight: 6,
};

export const dynamicInputValueContainer = (isDisabled, isReadOnly) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: isReadOnly ? 'default' : isDisabled ? 'not-allowed' : 'pointer',
  outline: 'none',

  '&:hover i':
    !isDisabled && !isReadOnly
      ? {
          opacity: 1,
        }
      : {},
});

export const dynamicInputField = (hasError) => (theme) => ({
  borderColor: hasError ? theme.errorDark : theme.primary,
});
// Input styles end (dynamic one)

// Dropdown styles start
export const dropdownInput = (hasError) => (theme) => hasError && { borderColor: theme.errorDark };
// DropDown styles end

// Dynamic dropdown styles start
export const dynamicDropdownField = (hasError) => (theme) =>
  [
    {
      paddingLeft: 9,
    },
    hasError
      ? {
          borderColor: theme.errorDark,
        }
      : {},
  ];

export const dynamicDropdownFieldContainer = {
  outline: 'none',
};

export const requiredText = {
  fontSize: '11px',
  textTransform: 'capitalize',
};

export const dynamicSwitchButtonFieldContainer = {
  outline: 'none',
  paddingTop: 6,
};

export const rightIconsContainer = {
  display: 'flex',
};

// Dynamic dropdown styles end

// Success Bar styles start
const successBarEffect = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const successBar = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.2)',
  backgroundColor: 'white',
  height: 70,
  overflow: 'hidden',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: 18,
};

export const successBarColorEffect = (theme) => ({
  width: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  backgroundColor: theme.successDark,
  animation: `${successBarEffect} 0.7s ease-in-out`,
});

export const successBarContent = {
  position: 'relative',
  zIndex: 10,
  width: '100%',

  '& > i': {
    position: 'absolute',
    right: '20px',
    top: '0',
    cursor: 'pointer',
  },
};
// Success Bar styles end

export const containerCheckBox = {
  fontWeight: 600,
  marginBottom: 16,
};

export const phoneInputContainer = {
  display: 'flex',
  width: '100%',
};

export const phoneInputCountrySelect = {
  width: 100,
  minWidth: 100,
  '& > div': {
    padding: 9,
  },
};

export const countryContainer = {
  display: 'flex',
  alignItems: 'center',
  padding: '6px 0',
  '& > .flag-img': {
    maxWidth: '24px',
    height: 'auto',
    marginRight: '8px',
    border: '3px solid white',
  },
};
