export const dropdownStyles = (theme) => ({
  borderColor: theme.whiteOpacity01,
  backgroundColor: theme.whiteOpacity01,
  borderRadius: 4,
  color: theme.white,
  minWidth: 1,
  width: 116,
  border: `1px solid ${theme.white}`,
  '& > div': {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.white}`,
  },
});

export const dropdownOptionsStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 6,
  backgroundColor: theme.newPrimary,
  color: theme.white,
  '& > h4': {
    fontSize: '0.875rem',
    fontFamily: 'Roboto, sans-serif;',
    fontWeight: 500,
  },
  '&:hover': {
    backgroundColor: theme.infoDark,
  },
});

export const dropdownValueStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  '& > img': {
    maxWidth: 24,
    marginRight: 8,
  },
  '& > h4': {
    color: theme.white,
    fontSize: 14,
    fontFamily: 'Roboto, sans-serif;',
    fontWeight: 500,
  },
});
