import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { durationInMinutes } from '../../../../../utils/durationInMinutes';
import Icon from '../../../../../components/UI/Icon';
import {
  carouselCardWrapper,
  carouselCardTextWrapper,
  carouselCardImgWrapper,
  carouselImgSpan,
  responsiveImg,
  buttonStyle,
} from './styles';
import defaultImg from '../../../../../assets/images/carouselImage.png';

const CarouselCard = ({
  title,
  category,
  description,
  lessons,
  totalVideoDurationInSeconds,
  image,
  isFeatured,
  id,
}) => {
  const { getText } = useTranslations();

  return (
    <div css={carouselCardWrapper}>
      <div css={carouselCardTextWrapper}>
        <h5 className="course-category">{category || 'Unknown Category'}</h5>
        <h3 className="course-heading">{title || 'Course Title'}</h3>
        <p className="course-description">{description || 'No description available.'}</p>
        <div className="btn-and-icons">
          <Link to={`/course-details/${id}`} css={buttonStyle}>
            {getText('viewCourse')}
          </Link>
          <div className="icon-and-text">
            <Icon iconName="la la-file" /> {lessons ?? 0} Lessons
          </div>
          <div className="icon-and-text">
            <Icon iconName="la la-clock" /> {durationInMinutes(totalVideoDurationInSeconds) ?? 'N/A'}
          </div>
        </div>
      </div>
      <div css={carouselCardImgWrapper}>
        <img src={image || defaultImg} alt="carousel-img" css={responsiveImg} />
        {isFeatured && <span css={carouselImgSpan}>{getText('featuredCourse')}</span>}
      </div>
    </div>
  );
};

CarouselCard.propTypes = {
  isFeatured: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  lessons: PropTypes.number,
  totalVideoDurationInSeconds: PropTypes.number,
  image: PropTypes.string,
  id: PropTypes.number,
};

export default CarouselCard;
