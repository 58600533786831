export const navBarContainer = (theme) => ({
  backgroundColor: theme.newPrimary,
  color: theme.primary,
  height: 64,
  position: 'fixed',
  zIndex: 1000,
  top: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const appAvatarIcon = {
  marginRight: 32,
};

export const navContainer = {
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  '& .la-bars': {
    justifyContent: 'flex-end',
  },
};

export const sidesContainer = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  '@media (max-width: 768px)': {
    display: 'none',
  },
};

export const linkStyles = (isRight) => (theme) => ({
  color: theme.white,
  fontSize: 12,
  border: 'none',
  marginLeft: 12,
  marginRight: isRight ? 24 : 12,
  textTransform: 'uppercase',
});

export const shopIconStyles = {
  marginLeft: 24,
  marginRight: 24,
};

export const profileIconStyles = (theme) => ({
  marginRight: '0.25rem',
  backgroundColor: theme.newPrimary,
  border: `1px solid ${theme.white}`,
  padding: '0.25em',
  borderRadius: '50%',
  color: theme.white,
  fontSize: '1rem',
  height: '30px',
  width: '30px',
});

export const profileDropdownBtn = (theme) => ({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& la-chevron-down': {
    color: theme.white,
    fontSize: '1rem',
  },
});

export const shopDropdownBtn = (theme) => ({
  height: 42,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& .icn-wrap': {
    position: 'relative',
    '& .icn-badge': {
      position: 'absolute',
      top: '-8px',
      right: '16px',
      width: '13px',
      height: '13px',
      borderRadius: '13px',
      color: theme.white,
      fontSize: '9px',
      fontWeight: '600',
      letterSpacing: '-0.11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '1',
      backgroundColor: theme.primary,
    },
  },
});

export const viewProfileDropdown = {
  minWidth: 260,
};
export const viewProfileStyle = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1em 2em',
  backgroundColor: theme.newPrimaryLighter,
  '& .wrapper': {
    paddingRight: '1em',
  },
  '& .swap-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: theme.newPrimaryLighter2,
    transform: 'rotate(-45deg) scaleX(-1)',
    '& i': {
      color: theme.white,
      cursor: 'pointer',
      fontSize: '0.875rem',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
  },
  '& .title': {
    color: theme.white,
    marginBottom: '0.25em',
    lineHeight: 1.1,
    fontSize: '0.875rem',
  },
  '& .label': {
    color: theme.newLavender,
    fontSize: '0.75rem',
  },
  '& .fa-chevron-right': {
    color: theme.white,
    fontSize: '1.125rem',
    transition: 'color .3s linear',
  },
  '&:hover': {
    '& .fa-chevron-right': {
      color: theme.white,
    },
  },
});

export const viewProfileMobile = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .wrapper': {
    textAlign: 'left',
    paddingRight: 16,
  },
  '& .swap-icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      color: theme.white,
      cursor: 'pointer',
      '&:hover': {
        color: theme.primaryLight,
      },
    },
  },
  '& .title': {
    color: theme.white,
    marginBottom: 4,
    lineHeight: 1.1,
  },
  '& .label': {
    color: theme.white,
    fontSize: 13,
  },
  '& .fa-chevron-right': {
    color: theme.white,
    fontSize: 18,
    transition: 'color .3s linear',
  },
  '&:hover': {
    '& .fa-chevron-right': {
      color: theme.white,
    },
  },
});

export const signOutStyle = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1em 2em',
  backgroundColor: theme.newPrimaryLighter,
  borderTop: `1px solid ${theme.borderLight}`,
  '& .la-sign-out': {
    marginRight: '0.25em',
    color: theme.white,
    fontSize: '1rem',
  },
  '& .title': {
    fontWeight: 300,
    color: theme.white,
    transition: 'color .3s linear',
    fontSize: '1rem',
  },
  '&:hover': {
    '& .title, .la-sign-out': {
      color: theme.primary,
    },
  },
});

export const signOutMobile = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .la-sign-out': {
    marginRight: 10,
    color: theme.white,
    fontSize: '1.5rem',
  },
  '& .title': {
    fontWeight: 300,
    color: theme.white,
  },
});

export const viewShopDropdown = (theme) => ({
  minWidth: '356px',
  '& .title': {
    margin: '1em 2em 1.5em',
    paddingBottom: '1em',
    fontSize: '13px',
    letterSpacing: '0.2px',
    color: theme.grayDark,
    fontWeight: '500',
    borderBottom: `1px solid ${theme.whiteGray}`,
  },
  '& .body': {
    '& .body-head': {
      padding: '0 2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .body-label': {
        fontSize: '0.75rem',
        letterSpacing: '0.2px',
        fontWeight: '600',
        color: theme.grayDark,
        textTransform: 'uppercase',
      },
    },
    '& .items-wrap': {
      '& .shop-item': {
        padding: '1em 2em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .shop-item-left': {
          display: 'flex',
          alignItems: 'center',
          '& .shop-item-image': {
            width: '48px',
            height: '40px',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginRight: '0.5em',
          },
          '& .shop-item-name': {
            fontSize: '0.8125em',
            letterSpacing: '0.2px',
            fontWeight: '500',
            color: theme.grayDark,
            '& > span': {
              fontSize: '0.6875rem',
              fontWeight: '300',
              color: theme.grayLight,
            },
          },
        },
        '& .shop-item-right': {
          textAlign: 'right',
          '& .shop-item-price': {
            fontSize: '0.875rem',
            fontWeight: '500',
            color: theme.grayDark,
          },
          '& .shop-item-fee': {
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
            color: '$label-gray',
          },
        },
        '&:nth-of-type(even)': {
          backgroundColor: theme.whiteGrayLight,
        },
      },
    },
  },
  '& .total-wrap': {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    padding: '0 2em',
    marginBottom: '1.5em',
    '& .total-label': {
      fontSize: '0.8125rem',
      letterSpacing: '0.5px',
      fontWeight: '500',
      color: theme.grayDark,
      marginRight: '0.5em',
    },
    '& .total-amount': {
      fontSize: '1.125rem',
      fontWeight: '600',
      letterSpacing: '-0.53px',
      color: theme.grayDark,
    },
  },
  '& .shop-btn': {
    margin: '0 2em 2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.grayLighter}`,
    borderRadius: '2px',
    padding: '1em 0.5em',
    cursor: 'pointer',
    '& .shop-btn-label': {
      fontSize: '0.8125rem',
      marginLeft: '0.5em',
      letterSpacing: '0.1px',
      color: theme.gray,
    },
    '&:hover': {
      transition: 'all 0.3s ease',
      backgroundColor: theme.primary,
      '& .shop-btn-label': {
        transition: 'all 0.3s ease',
        color: theme.white,
      },
    },
  },
});

export const accountDropdownStyle = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  zIndex: 9999,
};

export const accountDropdownInner = (theme) => ({
  minWidth: '250px',
  '& .account-list-scroll-wrap': {
    maxHeight: 300,
    overflowY: 'auto',
  },
  '& .title': {
    textTransform: 'uppercase',
    marginTop: 16,
    paddingBottom: '16px',
    fontSize: '12px',
    letterSpacing: '0.2px',
    color: theme.grayDark,
    fontWeight: '500',
    borderBottom: `1px solid ${theme.whiteGray}`,
  },
});

export const accountDropdownBtn = (theme) => ({
  padding: '6px 16px 12px 16px',
  borderRadius: 3,
  backgroundColor: theme.primary,
  color: theme.white,
  cursor: 'pointer',
  textAlign: 'left',
  '& .icon-wrap': {
    textAlign: 'center',
  },
  '& .label': {
    marginBottom: 6,
    paddingBottom: 4,
    fontSize: 14,
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.white}`,
  },
  '& .box-inner': {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    '& .id': {
      marginBottom: 4,
      fontWeight: 600,
    },
  },
});

export const accountsList = (theme) => ({
  position: 'relative',
  '& .header': {
    borderBottom: `1px solid ${theme.whiteGray}`,
    padding: '6px',
    background: theme.newPrimary,
    '& .back-button': {
      display: 'flex',
      alignItems: 'center',
      color: theme.white,
      marginBottom: 8,
      '& .la-angle-left': {
        fontSize: '1rem',
      },
      '&:hover': {
        color: theme.primaryLight,
      },
    },
    '& .nickname-search': {
      '& .search-icon > i': {
        fontSize: 18,
      },
      '& .close-icon > i': {
        fontSize: 18,
      },
      '& .search-input': {
        fontSize: 12,
        lineHeight: 1.2,
        backgroundColor: 'white',
        border: `1px solid ${theme.whiteGray}`,
      },
    },
  },
  '& .body': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25em',
    maxHeight: 300,
    overflowY: 'auto',
    padding: '6px',
    background: theme.newPrimary,
  },
});

export const accountLoaderWrap = (loading) => (theme) => ({
  background: theme.newPrimary,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 999,
  display: loading ? 'flex' : 'none',
});

export const accountChangeLoader = {
  width: 24,
  height: 24,
};

export const accountDropdownItem = (active) => (theme) => ({
  padding: '16px 32px',
  cursor: 'pointer',
  textAlign: 'left',
  backgroundColor: active ? theme.white : theme.newPrimary,
  color: active ? theme.newPrimary : theme.white,
  borderBottom: `1px solid ${theme.whiteGray}`,
  border: active ? `1px solid ${theme.newPrimary}` : `1px solid ${theme.white}`,
  '& .item-id': {
    fontWeight: 300,
  },
  '& .item-name': {
    fontSize: 14,
  },
  '&:hover': {
    backgroundColor: theme.infoDark,
    color: theme.white,
  },
});

export const profilePicName = {
  display: 'flex',
  gap: '0.25em',
  alignItems: 'center',
};

export const hamburgerStyle = (theme) => ({
  color: theme.white,
  display: 'none',
  '@media (max-width: 768px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const hamburgerDropdownStyles = (theme) => ({
  position: 'absolute',
  // top: 60,
  right: 0,
  backgroundColor: theme.newPrimary,
  border: `1px solid ${theme.white}`,
  color: theme.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: 4,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  padding: 0,
  '> li': {
    padding: 12,
    listStyle: 'none',
    alignSelf: 'flex-start',
  },
  '> li:first-of-type': {
    backgroundColor: theme.newPrimaryLighter,
    borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    '& .title': {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    '& .label': {
      color: theme.newLavender,
      fontWeight: 400,
      fontSize: '0.75rem',
      fontFamily: 'Roboto, sans-serif;',
    },
    '& .swap-icon': {
      display: 'flex',
      background: theme.newPrimaryLighter2,
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      fontSize: '0.75rem',
      transform: 'rotate(-45deg) scaleX(-1)',
    },
  },
  '> li:last-of-type': {
    marginTop: '5em',
  },
  '& .title-sign-out': {
    fontWeight: 500,
    fontSize: '0.875rem',
    fontFamily: 'Roboto, sans-serif;',
  },
  '& a': {
    margin: 0,
    color: theme.white,
    textDecoration: 'none',
    textTransform: 'none',
    fontFamily: 'Roboto, sans-serif;',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '21px',
  },
  '@media (min-width:769px)': {
    display: 'none',
  },
});

export const titleAndLabelDropdown = {
  display: 'flex',
  flexDirection: 'column',
};
