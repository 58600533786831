import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hash from 'object-hash';
import { useAuth, showApiError } from '../../../components/UI';
import { getCourseById } from '../../../services/courses';
import { postNewAccountCourse } from '../../../services/accountCourse';

export const useCourse = () => {
  const { id: courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [step, setStep] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getCourse();
  }, [courseId, hash({ user })]);

  const getCourse = async () => {
    const [res, err] = await getCourseById(courseId, user?.academyAccount?.id);
    if (err) return showApiError(err);

    let accountCourse = null;

    if (!res?.accountCourse) {
      const [resNewAccount, errNewAccount] = await postNewAccountCourse({
        courseId,
        accountId: user?.academyAccount?.id,
      });

      if (resNewAccount) accountCourse = resNewAccount;
      setIsAvailable(!(!resNewAccount || errNewAccount));
    } else setIsAvailable(true);

    setCourse({ ...res, accountCourse: accountCourse ?? res.accountCourse });
  };

  const handleStepChange = () => {};

  return {
    course,
    isAvailable,
    step,
    handleStepChange,
  };
};
