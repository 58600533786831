export const wrapper = {
  isolation: 'isolate',
  '& .react-multi-carousel-dot-list': {
    bottom: '15px',
  },
};

export const avatarWrap = (theme) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
  flexGrow: 1,
  '& .userName': {
    color: theme.white,
    marginBottom: 20,
  },
});

export const avatarImgWrap = {
  position: 'relative',
  marginRight: 40,
};

export const avatarImgStyle = {
  height: 120,
  width: 120,
  borderRadius: 120,
  objectFit: 'cover',
};

export const avatarNoImgWrap = (theme) => ({
  height: 120,
  width: 120,
  borderRadius: 120,
  marginRight: 40,
  backgroundColor: theme.whiteGray,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > h2': {
    fontSize: 32,
    color: theme.white,
  },
});

export const editBtnStyle = (theme) => ({
  position: 'relative',
  fontSize: 12,
  color: theme.white,
  textTransform: 'uppercase',
  border: `2px solid ${theme.white}`,
  padding: '0.25em 0.5em',
  transition: 'color .3s linear',
  '&:hover': {
    color: theme.primary,
    border: `2px solid ${theme.primary}`,
  },
});

export const planImgStyle = {
  height: 50,
  width: 60,
  objectFit: 'contain',
  position: 'absolute',
  top: 0,
  right: -25,
};

export const badgesWrap = (theme) => ({
  '& > .label': {
    color: theme.secondaryLighter05,
    textTransform: 'upperCase',
    fontWeight: 500,
    marginBottom: 24,
    fontSize: 14,
    display: 'block',
  },
});

export const badgesBoxWrap = {
  display: 'flex',
  alignItems: 'centre',
  marginLeft: -12,
  marginRight: -12,
  '& .badge-wrap': {
    marginLeft: 12,
    marginRight: 12,
  },
};

export const badgeBox = (image) => (theme) => ({
  width: 48,
  height: 48,
  borderRadius: 48,
  backgroundColor: theme.whiteGray,
  backgroundImage: `url(${image})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const badgesTooltipWrap = (widthIndex) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: widthIndex * 88,
  marginLeft: -4,
  marginRight: -4,
  '& .badges-tooltip-wrap': {
    width: 80,
    marginLeft: 4,
    marginRight: 4,
    padding: 4,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

export const dashboardHeaderText = (theme) => ({
  color: theme.white,
});

export const dashboardHeaderTextAndBtnWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  alignItems: 'center',
};

export const dashboardBtn = (theme) => ({
  borderRadius: 34,
  color: theme.white,
});

export const customArrowStyle = (direction) => (theme) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  textAlign: 'center',

  '.carousel-arrow': {
    background: 'transparent',
    border: 'none',
    color: theme.white,
    fontSize: '1.5rem',
    transform: `translateX(${direction === 'left' ? '-70px' : '70px'})`,
    outline: 0,
    position: 'relative',
    zIndex: 1000,
  },
});

export const customDotStyle = (theme, active) => ({
  width: '24px',
  height: '3px',
  backgroundColor: active ? theme.courseProgress : theme.white,
  margin: '0 0.25em',
  cursor: 'pointer',
});
