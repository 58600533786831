import { headerContainer } from '../../../components/UI/Paper';

export const contentContainer = {
  width: '100%',
  minHeight: '100%',
  height: 'calc(100vh - 80px)',
  marginTop: '5em',
};

export const container = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1em',
  width: '100%',
  height: '100%',
  minHeight: 'calc(100vh - 50px)',
  backgroundColor: theme.newPrimary,
  marginTop: '-4em',
});

export const formTitleContainer = {
  textAlign: 'center',
  marginTop: '-9.5rem',
};

export const loginTitle = (theme) => [
  headerContainer,
  {
    textAlign: 'left',
    backgroundColor: theme.newPrimary,
  },
];

export const title = (theme) => ({
  color: theme.grayDark,
  fontWeight: 600,
  marginBottom: '0.625em',
  fontSize: '1.75rem',
});

export const detailStylesLogin = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
  alignItems: 'center',
};

export const loginSubtitle = (theme) => ({
  fontSize: '0.75rem',
  lineHeight: 1.71,
  color: theme.white,
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
});

export const forgotStyles = (theme) => ({
  display: 'flex',
  gap: '0.5em',
  alignItems: 'center',
  justifyContent: 'center',
  '& > p': {
    color: theme.white,
    fontSize: '0.75rem',
    fontFamily: 'Roboto, sans-serif;',
    fontWeight: 400,
  },
  '& > a': {
    fontSize: '0.75rem',
    color: theme.newLavender,
    fontFamily: 'Roboto, sans-serif;',
    fontWeight: 400,
    '&:hover': {
      color: theme.infoDark,
    },
  },
});

export const formContainer = {
  padding: '3em',
};

export const formInputContainer = {
  marginBottom: '1.25em',
};

export const titleForgot = (theme) => ({
  color: theme.grayDark,
  fontWeight: 600,
  marginBottom: '0.625em',
  fontSize: '1.25rem',
});

export const backButton = (theme) => ({
  color: theme.infoDark,
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,
  border: `1px solid ${theme.infoDark}`,
  padding: '0 2em',

  '&: hover': {
    backgroundColor: theme.infoLight,
  },
});

export const submitButtonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};
