import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Row, Col } from '../../../UI';
import backUpImg from '../../../../assets/images/courseBackUpImg.png';
import { formatShortText } from '../../../../utils/textFormat';
import { durationInMinutes } from '../../../../utils/durationInMinutes';
import { courseBox, buttonStyle, btnAndDurationCourseCard, customCol } from './styles';

const CourseCategoryCard = ({ course }) => {
  const { getText } = useTranslations();

  return (
    <div css={courseBox}>
      <Row className="row-wrapper">
        <Col>
          <div className="img-wrapper">
            <img src={course?.pictureUrl ?? backUpImg} alt="course-img" className="course-image" />
          </div>
        </Col>
        <Col css={customCol}>
          <div className="course-text-box">
            <div className="text-wrapper">
              <h2 className="title">{course?.name}</h2>
              <p className="course-category">
                <span>
                  <i className="fas fa-graduation-cap" />
                </span>
                {course?.category?.name}
              </p>
              {course?.description && <p className="text">{formatShortText(course?.description)}</p>}
            </div>
            <div css={btnAndDurationCourseCard}>
              <Link to={`/course-details/${course?.id}`} css={buttonStyle}>
                {getText('buyNow')}
              </Link>
              <p>
                <i className="far fa-clock" /> {durationInMinutes(course?.totalVideoDurationInSeconds)}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

CourseCategoryCard.propTypes = {
  course: PropTypes.object,
};

export default CourseCategoryCard;
