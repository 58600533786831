export default {
  en: {
    nextPage: 'Next page',
    previousPage: 'Previous page',
    next: 'Next',
    previous: 'Previous',
    courses: 'Courses',
    course: 'course',
    products: 'Products',
    getStarted: 'Get Started',
    startNow: 'Start Now',
    dashboard: 'Dashboard',
    product: 'Product',
    experts: 'Experts',
    language: 'Language',
    badges: 'Badges',
    earnedBadges: 'Earned Badges',
    editProfile: 'Edit Profile',
    profile: 'Profile',
    resources: 'Resources',
    aboutCourseTab: 'About',
    overviewCourseTab: 'Overview',
    materialsCourseTab: 'Materials',
    certificateCourseTab: 'Certificate',
    quizResultsCourseTab: 'My quiz results',
    courseOverview: 'Course Overview',
    courseMaterials: 'Course Materials',
    continueLearning: 'Continue Learning',
    seeAll: 'See all',
    seeMore: 'See More',
    completed: 'Completed',
    inProgress: 'In progress',
    friendsRankList: 'Friends rank list',
    courseCurriculum: 'Course curriculum',
    rankingList: 'Ranking list',
    recommendedToYou: 'Recommended to you',
    recommendedCoursesForYou: 'Recommended courses for you',
    recommendedCategories: 'Recommended categories',
    coursesMightLike: 'You might also like',
    peopleWatching: 'People watching',
    lessons: 'Lessons',
    myCourses: 'My Courses',
    allCourses: 'All Courses',
    courseInclude: 'The course includes:',
    pickCategory: 'Pick a category',
    achievements: 'Achievements',
    yourAchievements: 'Your achievements',
    welcomeBack: 'Welcome back',
    achievementsSoFar: 'Here are your achievements so far',
    hey: 'Hey',
    shareIt: 'Share it',
    worldKnow: 'Want to know the world?',
    learnAndEarn: 'Learn & earn',
    achievementsBadges: 'Achievements are a way for you to earn badges and points while you’re learning',
    howItWork: 'How doest it work?',
    totalPointsEarned: 'total points earned',
    yourBadges: 'Your badges',
    coursesCompleted: 'courses completed',
    quizesCompleted: 'quizes completed',
    expertTeacher: 'Expert Teacher',
    description: 'Description',
    whatLearn: "What you'll learn",
    viewProfile: 'View Profile',
    viewDetails: 'View Details',
    viewAll: 'View all',
    viewCourse: 'View course',
    featuredCourse: 'Featured course',
    settings: 'Settings',
    paymentMethods: 'Payment Methods',
    signIn: 'Sign in',
    signInToOneAcademy: 'Sign in to OneAcademy',
    signOut: 'Sign Out',
    oneAcademyCourses: 'One Academy courses',
    youHave: 'You have',
    pleaseFillFetailsBelow: 'Please fill the details below',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Forgot your password',
    resetItHere: 'Reset it here',
    resetYourPassword: 'Reset your password for One Academy',
    logInInstead: 'Log in instead',
    yourEmail: 'Your Email',
    shoppingCart: 'Shopping Cart',
    item: 'Item',
    price: 'Price',
    fee: 'fee',
    total: 'Total',
    viewFullCart: 'View Full Cart',
    personalInformation: 'Personal Information',
    contactInformation: 'Contact Information',
    idInformation: 'ID Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    countryOfBirth: 'Country of Birth',
    cityOfBirth: 'City of Birth',
    dateOfBirth: 'Date of Birth',
    idNumber: 'ID Number',
    idType: 'ID Type',
    idExpiration: 'Exp. Date',
    emailAddress: 'Email Address',
    confirmEmailAddress: 'Confirm your email address',
    phoneNumber: 'Phone Number',
    address: 'Address',
    country: 'Country',
    city: 'City',
    postalCode: 'Postal Code',
    updateProfile: 'Update profile',
    submit: 'Submit',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    bankDetails: 'Bank Details',
    accountHolderName: 'Account Holder Name',
    bankName: 'Bank Name',
    bankCountry: 'Bank Country',
    ibanAccount: 'IBAN Account',
    bicSwiftCode: 'BIC / SWIFT Code',
    bank: 'Bank',
    bankProvince: 'Bank Province',
    bankCity: 'Bank City',
    bankBranch: 'Bank Branch',
    cardHolderName: 'Card Holder Name',
    cardNumber: 'Card Number',
    accountNumber: 'Account Number',
    all: 'All',
    meetТheExperts: 'Meet the Experts',
    accomplishments: 'Accomplishments',
    biography: 'Biography',
    ourProducts: 'Our Products',
    giftCode: 'Gift Code',
    redeem: 'Redeem',
    redeemGiftCode: 'Redeem Gift code',
    redeemed: 'Redeemed',
    redeemedBy: 'Redeemed By',
    notRedeemed: 'Not Redeemed',
    notPaid: 'Not Paid',
    buyModule: 'Buy Module',
    buyNow: 'Buy now',
    myModules: 'My Modules',
    moduleName: 'Module Name',
    purchaseHistory: 'Purchase History',
    educationalModules: 'Educational Modules',
    searchModules: 'Search Modules',
    sortByValue: 'Sort by: Value',
    sortByDate: 'Sort by: Date',
    sortByName: 'Sort by: Name',
    noModulesForThisSearch: 'There are no modules from this search',
    noForPurchaseHistoryThisSearch: 'No order history found for this search',
    withActivationFee: 'With activation fee',
    addToCard: 'Add to card',
    cartTotal: 'Cart Total',
    checkOutNow: 'Check out now',
    bankWireTransfer: 'Bank Wire Transfer',
    bankWireP1:
      'Following Your Bank Wire Request To Purchase The NewLife Package, The OneLife Team Would Like To Kindly Refer You To An Official OneLife Member For Your Region Who Will Handle The Payment For You.',
    bankWireP2:
      'This Procedure Is Necessary So That We Can Ensure That Your Payment Will Get Processed Effectively And On Time.',
    bankWireP3: 'You Can Use The Bank Wire Payment By Contacting Distributor:',
    bankWireP4: 'Please Don’t Forget To Include The Following Information:',
    bankWireP5: 'Alternatively, You Can UseOne Of The Other Payment Methods Available!',
    bankWireP6: 'Thank You For Your Understanding!',
    bankWireP7: 'Best Wishes,',
    bankWireP8: 'The OneLife Team',
    cashWallet: 'Cash Wallet',
    ballance: 'Ballance',
    purchaseWith: 'Purchase with',
    yourOrderCompletedSuccessfully: 'Your order was completed successfully!',
    orderFail: 'Order fail!',
    yourOrderCompletedSuccessfullyMsg:
      'An email including the details about your order has been sent to your email address. Please keep it for your records and check the instructions for further steps.',
    orderFailMsg: 'Please contact our support agent for more details at',
    backToShop: 'Back to Shop',
    businessVolume: 'Business Volume',
    whatBusinessVolume: 'What’s the Business volume',
    tokens: 'Tokens',
    howManyTokensGet: 'How many tokens you get?',
    splits: 'Splits',
    theAmountPeopleBelow: 'The amount of people below',
    difficultyReduction: 'Difficulty Reduction',
    immediateMining: 'Immediate Mining',
    isAllowed: 'is Allowed',
    isNotAllowed: 'is Not Allowed',
    bonusApplicableFor: 'Bonus Applicable for',
    network: 'Network',
    matching: 'Matching',
    level: 'level',
    earnedOn: 'earned on',
    level1st: '1st Level',
    level2nd: '2nd Level',
    level3rd: '3rd Level',
    level4th: '4th Level',
    bonusCap: 'Bonus Cap',
    newlifePrice: 'Newlife Price',
    success: 'Success',
    warningFail: 'Warning & Fail',
    warning: 'Warning',
    noticeUpdate: 'Notice & Update',
    successfullyRedeemPackage: 'You have successfully redeem module.',
    successfullyEditedProfile: 'You have successfully edited your profile.',
    successfullyUpdatedPaymentMethod: 'You have successfully updated payment method.',
    successfullyAddPaymentMethod: 'You have successfully add payment method.',
    successfullyAddModuleToCard: 'You have successfully added module to shopping cart.',
    moduleNotVisibleOrActive: 'This module is not visible or active!',
    failedLogin: 'Failed login',
    invalidUsernamePassword: 'Invalid username or password',
    successfullySwitchAccount: 'You have successfully switch account',
    copy: 'Copy',
    copyMessage: 'Copy to clipboard',
    quizCompleted: 'Quiz Compleat',
    activeQuestion: (current, total) => `Question ${current} of ${total}`,
    skipQuiz: 'Skip Quiz',
    retakeQuiz: 'Retake Quiz',
    score: 'Score',
    points: 'Points',
    youScoreIs: 'You score is',
    answerTo: (title) => `Answer to "${title}" is?`,
    yourAnswerTo: (title) => `Your answer to "${title}" is?`,
    loadingDocument: 'Loading document',
    downloadCertificate: 'Download Certificate',
    correct: 'Correct',
    wrong: 'Wrong',
    loadMore: 'Load More',
    noQuizResultMessage: 'There are no quiz results for this course.',
    footerText:
      'OneAcademy is an innovative e-learning platform covering a broad range of financial topics including trading, the stock exchange, cryptocurrency, financial analysis, asset management and more.',
    termsConditions: 'Terms and conditions',
    privacyPolicy: 'Privacy policy',
    allRightReserved: 'All right reserved',
    changeAccount: 'Change Account',
    currentAccount: 'Current Account',
    certificate: 'Certificate',
    ofCompletion: 'of Completion',
    founderCeo: 'Founder & CEO',
    certificateSuccess: "Has successfully completed with distinction OneAcademy's Course",
    open: 'Open',
    theEnd: 'The End',
    upNext: 'Up Next',
    openPff: 'Click to open the PDF.',
    buyPackage: 'Buy Package',
    UpgradePackageText: 'Upgrade your package to unlock this course.',
  },
};
