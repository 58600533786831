export const recommendedWrapper = {
  paddingBottom: '2em',
};

export const categoriesWrapperRow = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-15px',
};

export const categoriesCardsWrapper = {
  flex: '0 0 33%',
  padding: '15px',
  '@media (max-width:1200px)': {
    flex: '0 0 50%',
  },
  '@media (max-width:768px)': {
    flex: '0 0 100%',
  },
};

export const categoryCardStyle = (theme) => ({
  border: `1px solid ${theme.newGray4}`,
  borderRadius: 2,
  padding: '2em 6em',
  textAlign: 'center',

  '& > img': {
    marginBottom: '1em',
  },
  '& h5': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    color: theme.newGray3,
  },
});
