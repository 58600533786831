export const wrapper = (theme) => ({
  backgroundColor: theme.white,
  '& .bottomBuffer': {
    marginBottom: '1.5em',
  },
});

export const tabContainer = (theme) => ({
  padding: '2em 3em',
  backgroundColor: theme.newBackgroundLight,
});

export const aboutTab = (theme) => ({
  '& p': {
    color: theme.grayLight,
    fontSize: '1rem',
    lineHeight: 1.5,
    marginBottom: '1.25em',
  },
});
