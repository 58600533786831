import { useTranslations } from '@veraio/strank';
import { Row, Col } from '../../../UI';
import { headerWrap, heading, bigHeadingHeader } from './styles';

const AllCoursesHeader = () => {
  const { getText } = useTranslations();

  return (
    <Row css={headerWrap} container>
      <Row horizontalGap={12} noWrap container>
        <Col xs={12}>
          <h6 css={heading}>{getText('courses')}</h6>
        </Col>
      </Row>
      <Row horizontalGap={12} noWrap container>
        <Col xs={12}>
          <h3 css={bigHeadingHeader}>{getText('oneAcademyCourses')}</h3>
        </Col>
      </Row>
    </Row>
  );
};

export default AllCoursesHeader;
