// import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Row } from '../../../UI';
import { useAuth } from '../../../UI';
// import { getAccountAchievements } from '../../../../services/accounts';
// import badgeImg from '../../../../assets/images/no-badge-thumb.png';
import learnAndEarnImg from '../../../../assets/images/learnAndEarnImg.svg';
import achievementsMedal from '../../../../assets/images/achievements-medal.svg';
import courseComplete from '../../../../assets/images/coursesCompletedCheck.svg';
import quizesCompleted from '../../../../assets/images/quizesCompletedCheck.svg';
import level2Badge from '../../../../assets/images/level2badge.svg';
import {
  rowWrapper,
  learnEarnWrapper,
  headingStyles,
  subHeadingAchievements,
  knowWorldWrapper,
  learnEarnTextWrapper,
  learEarnTitle,
  learEarnText,
  learnEarnButton,
  responsiveImg,
  levelWrapper,
  leftSide,
  rightSide,
  cardsWrapper,
  cardWrapper,
  learnEarnCard,
  medalIcons,
  achievementPoints,
  pointsEarned,
  yourBadgesWrapper,
  yourBadgesTitle,
  badgeContent,
  badgeWrapper,
  badgeWrapperRow,
  pageWrapper,
  // avatarImgWrap,
  // avatarImgStyle,
  // avatarNoImgWrap,
  // badgesWrap,
  // badgesTooltipWrap,
  // badgesBoxWrap,
  // badgeBox,
} from './styles';
import FriendsRankList from '../../Dashboard/FriendsRankList';

const YourAchievements = () => {
  const { getText } = useTranslations();
  const {
    user: { profile },
    // academyAccount: { id: accountId } = {},
  } = useAuth();

  // const [achievements, setAchievements] = useState(null);

  // const auth = useAuth();
  // console.log('achievements', achievements);
  // console.log('auth.user.academyAccount:', auth.user?.academyAccount?.id);

  // useEffect(() => {
  //   fetchAchievements();
  // }, [accountId]);

  // const fetchAchievements = async () => {
  //   const [res, err] = await getAccountAchievements(accountId);
  //   if (err) return showApiError(err);

  //   setAchievements(res?.badges ?? []);
  // };

  return (
    <div css={pageWrapper}>
      <Row container css={rowWrapper}>
        <div>
          <h3 css={headingStyles}>{getText('yourAchievements')}</h3>
          <h6 css={subHeadingAchievements}>{`${getText('hey')}, ${profile?.firstName}. ${getText(
            'achievementsSoFar',
          )}.`}</h6>
        </div>
        <div css={knowWorldWrapper}>
          <h6 css={subHeadingAchievements}>{getText('worldKnow')}</h6>
          <Button primary>{getText('shareIt')}</Button>
        </div>
      </Row>
      <Row container css={learnEarnWrapper}>
        <div css={learnEarnTextWrapper}>
          <h5 css={learEarnTitle}>{getText('learnAndEarn')}</h5>
          <p css={learEarnText}>{getText('achievementsBadges')}</p>
          <Button css={learnEarnButton}>{getText('howItWork')}</Button>
        </div>
        <div>
          <img src={learnAndEarnImg} alt="learn-earn-img" css={responsiveImg} />
        </div>
      </Row>
      <Row container css={levelWrapper}>
        <div css={leftSide}>
          <div css={cardsWrapper} className="test">
            <div css={cardWrapper}>
              <div className="learn-earn-card" css={learnEarnCard}>
                <img src={achievementsMedal} alt="achievement-medal" css={medalIcons} />
                <h4 css={achievementPoints}>82</h4>
                <p css={pointsEarned}>{getText('totalPointsEarned')}</p>
              </div>
            </div>
            <div css={cardWrapper}>
              <div className="learn-earn-card" css={learnEarnCard}>
                <img src={courseComplete} alt="course-complete" css={medalIcons} />
                <h4 css={achievementPoints}>82</h4>
                <p css={pointsEarned}>{getText('coursesCompleted')}</p>
              </div>
            </div>
            <div css={cardWrapper}>
              <div className="learn-earn-card" css={learnEarnCard}>
                <img src={quizesCompleted} alt="quizes-completed" css={medalIcons} />
                <h4 css={achievementPoints}>82</h4>
                <p css={pointsEarned}>{getText('quizesCompleted')}</p>
              </div>
            </div>
          </div>
          <div className="badges-wrapper" css={yourBadgesWrapper}>
            <p css={yourBadgesTitle}>{getText('yourBadges')}</p>
            <div css={badgeWrapperRow}>
              <div css={badgeWrapper}>
                <div css={badgeContent}>
                  <img src={level2Badge} alt="" />
                  <h6>{`${getText('level')}: 2`}</h6>
                  <p>{`${getText('earnedOn')} 16 sept`}</p>
                </div>
              </div>
              <div css={badgeWrapper}>
                <div css={badgeContent}>
                  <img src={level2Badge} alt="" />
                  <h6>{`${getText('level')}: 2`}</h6>
                  <p>{`${getText('earnedOn')} 16 sept`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div css={rightSide}>
          <FriendsRankList />
        </div>
      </Row>
    </div>
  );
};

export default YourAchievements;

{
  /* {!isEmpty(accomplishments) && (
          <Col sm="auto">
            <div css={badgesWrap}>
              <span className="label">{getText('earnedBadges')}</span>
              <div css={badgesBoxWrap}>
                <>
                  {accomplishments.map(
                    (el, index) =>
                      (index <= 2 || accomplishments.length === 4) && (
                        <Tooltip position="bottom" content={<p>{el?.name}</p>} key={el.id}>
                          <div className="badge-wrap">
                            <div css={badgeBox(el?.pictureUrl && el?.pictureUrl?.length ? el.pictureUrl : badgeImg)} />
                          </div>
                        </Tooltip>
                      ),
                  )}
                  {accomplishments.length > 4 && (
                    <Tooltip
                      position="left"
                      content={
                        <div css={badgesTooltipWrap(Math.min(accomplishments.length - 2, 3))}>
                          {accomplishments.map(
                            (el, index) =>
                              index > 1 && (
                                <div className="badges-tooltip-wrap" key={el.id}>
                                  <div
                                    css={badgeBox(el?.pictureUrl && el?.pictureUrl?.length ? el.pictureUrl : badgeImg)}
                                  />
                                  <p>{el?.name}</p>
                                </div>
                              ),
                          )}
                        </div>
                      }>
                      <div className="badge-wrap">
                        <div css={badgeBox}>+{accomplishments.length - 3}</div>
                      </div>
                    </Tooltip>
                  )}
                </>
              </div>
            </div>
          </Col>
        )} */
}
