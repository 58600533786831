import PropTypes from 'prop-types';
import { Button, Icon } from '../../../UI';
import { ReactComponent as PlayIcn } from '../../../../assets/images/PlayCircleNotWatched.svg';
import { ReactComponent as QuizIcn } from '../../../../assets/images/PlayCircleQuiz.svg';
import { asideStyle, videoItemWrap } from './styles';

const renderListIcon = (type) => {
  const defaultIcon = <PlayIcn className="icon" />;

  switch (type) {
    case 'video':
      return defaultIcon;
    case 'text':
      return (
        <div className="icon">
          <Icon iconName="picture_as_pdf" material />
        </div>
      );
    case 'quiz':
      return <QuizIcn className="icon" />;
    default:
      return defaultIcon;
  }
};

const CourseCurriculum = ({ course, isAvailable, onChange, step }) => {
  return (
    <aside css={asideStyle}>
      <ul className="course-curriculum-list">
        {course?.steps.map((el, index) => (
          <li key={el.id} css={videoItemWrap(el?.id === step?.stepId, isAvailable || el?.isFree)}>
            <Button
              outline
              onClick={() => (isAvailable || el?.isFree) && onChange(el)}
              className="course-curriculum-item">
              {renderListIcon(el.type)}
              <p>
                {index + 1}. {el?.title}
              </p>
            </Button>
          </li>
        ))}
      </ul>
    </aside>
  );
};

CourseCurriculum.propTypes = {
  course: PropTypes.object,
  isAvailable: PropTypes.bool,
  onChange: PropTypes.func,
  step: PropTypes.object,
};

export default CourseCurriculum;
