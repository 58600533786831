import { useEffect, useState } from 'react';
import { isNil, isNull } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Skeleton, showApiError, Dropdown } from '../../../components/UI';
import CourseCategoryCard from '../../../components/Pages/Course/CourseCategoryCard';
import { AllCoursesHeader } from '../../../components/Pages/AllCourses';
import { getAllCategories } from '../../../services/categories';
import { getAllCourses } from '../../../services/courses';
import {
  wrapper,
  skeletonStyle,
  categoryAndCardWrapper,
  sideBarStyle,
  contentStyle,
  pickCategory,
  customColCategoryCard,
  dropdownCategory,
} from './styles';

const AllCourses = () => {
  const [categories, setCategories] = useState(null);
  const [activeCategory, setActiveCategory] = useState(0);
  const [courses, setCourses] = useState({});
  const { getText } = useTranslations();

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    isNil(courses[activeCategory]) && fetchCategoryCourses();
  }, [activeCategory]);

  const fetchCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return showApiError(err);

    setCategories(res?.data ?? []);
  };

  const fetchCategoryCourses = async () => {
    const [res, err] = await getAllCourses({ categoryId: activeCategory });
    if (err) return showApiError(err);

    setCourses((prev) => ({ ...prev, [activeCategory]: res?.data ?? [] }));
  };

  const handleCategorySelect = (categoryId) => {
    setActiveCategory(categoryId);
  };

  const renderCategories = () => {
    const allCategories = [{ id: 0, name: getText('all') }, ...categories];

    return (
      <div css={sideBarStyle}>
        {allCategories.map((category) => (
          <div
            role="button"
            tabIndex={0}
            key={category.id}
            className="category-item"
            data-active={activeCategory === category.id}
            onClick={() => setActiveCategory(category.id)}>
            <span>{category?.icon ? category?.icon : <i className="fas fa-th" />}</span>
            {category.name}
          </div>
        ))}
      </div>
    );
  };

  const renderDropdown = () => {
    if (isNull(categories)) return null;

    const allCategories = [{ id: 0, name: getText('all') }, ...categories];
    const options = allCategories.map((category) => ({
      label: category.name,
      value: category.id,
    }));

    return (
      <Dropdown
        options={options}
        autoWidth
        value={options.find((option) => option.value === activeCategory)}
        css={dropdownCategory}
        onChange={(option) => handleCategorySelect(option.value)}
      />
    );
  };

  const renderCards = () => {
    if (isNil(categories) || isNil(courses[activeCategory])) {
      return Array(5)
        .fill()
        .map((_, index) => (
          <div key={index} css={skeletonStyle}>
            <Skeleton height="280px" width="100%" />
          </div>
        ));
    }

    return courses[activeCategory].map((course) => <CourseCategoryCard key={course.id} course={course} />);
  };

  return (
    <div css={wrapper}>
      <section className="header">
        <AllCoursesHeader />
      </section>
      <Row container css={pickCategory}>
        <h5 className="section-title">{getText('pickCategory')}</h5>
        {renderDropdown()}
      </Row>

      <Row container horizontalGap={12} noWrap css={categoryAndCardWrapper}>
        <Col className="categories-sidebar">
          {!isNull(categories) ? renderCategories() : <Skeleton height="300px" width="100%" />}
        </Col>

        <Col css={customColCategoryCard}>
          <div css={contentStyle}>{renderCards()}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AllCourses;
