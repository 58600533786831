import { Link } from 'react-router-dom';
import { isArray, isPlainObject } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import { Paper, Form, FormInput, validate, showError } from '../../../components/UI';
import loginLogo from '../../../assets/images/logoLogin.svg';
import { container, formTitleContainer, loginTitle, detailStylesLogin, loginSubtitle, forgotStyles } from './styles';

const Login = () => {
  const { getText } = useTranslations();

  const onSubmit = async (values) => {
    const res = await loginWithPassword(values);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message.split(' Http')[0]) ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map((el) => el[0]));
    //     Error from sts identity                  Default behavior
    err && (isArray(err) ? err.forEach(showError) : showError(err));
  };

  return (
    <div css={container}>
      <div css={formTitleContainer}>
        <Paper
          header={
            <div css={loginTitle}>
              <div css={detailStylesLogin}>
                <img src={loginLogo} alt="login-logo" />
                <h5 css={loginSubtitle}>{getText('signInToOneAcademy')}</h5>
              </div>
            </div>
          }>
          <Form
            onSubmit={onSubmit}
            submitButton={{
              children: getText('signIn'),
              primary: true,
              css: { width: '100%', justifyContent: 'center' },
            }}
            preFilledValues={{
              email: '',
              password: '',
            }}>
            <FormInput id="email" placeholder={getText('emailAddress')} required />
            <FormInput
              id="password"
              type="password"
              placeholder={getText('password')}
              required
              validate={(val) => validate(val, ['required', 'password'])}
            />
          </Form>
          <div css={forgotStyles}>
            <p>{getText('forgotPassword')}?</p>
            <Link to="/forgot-password">{getText('resetItHere')}</Link>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
